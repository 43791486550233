import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { em, hideVisually } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import CategorySvg from "../category-svg"

export default function FeaturedCategories() {
  const { categories, translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          categories
        }
      }

      categories: allCategoriesJson(
        filter: { _featured: { eq: true } }
        sort: { name: ASC }
      ) {
        nodes {
          id: nk_id
          name
          path: _type_path
        }
      }
    }
  `)

  if (!categories.nodes.length) return null

  return (
    <Container>
      <div>
        <Title
          dangerouslySetInnerHTML={{ __html: translations.global.categories }}
        />

        {!!categories.nodes.length && (
          <Categories>
            {categories.nodes.map((category) => (
              <li key={category.id}>
                <CategoryLink to={category.path}>
                  <CategorySvg id={category.id} />

                  <span>{category.name}</span>
                </CategoryLink>
              </li>
            ))}
          </Categories>
        )}
      </div>
    </Container>
  )
}

const Container = styled.section`
  padding-top: ${em(90)};
  padding-bottom: ${em(90)};
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.midnatt};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-top: ${em(40)};
    padding-bottom: ${em(40)};
  }

  &::before {
    content: "";
    width: 120%;
    max-width: ${em(1440)};
    aspect-ratio: 7 / 2;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    background-image: ${({ theme }) =>
      theme.helpers.fish1Url(theme.colors.morkbla)};
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

  > div {
    ${({ theme }) => theme.grid.container()}

    position: relative;
    z-index: 2;
  }
`

const Title = styled.h2`
  ${hideVisually()}
`

const Categories = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: ${em(18)};

  @media ${({ theme }) => theme.mq.smallDown} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    font-size: ${em(14)};
  }

  @media ${({ theme }) => theme.mq.xxsmallDown} {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  @media ${({ theme }) => theme.mq.xsmallDown} {
    grid-template-columns: repeat(
      ${({ $count }) => Math.floor($count / 3)},
      minmax(0, 1fr)
    );
    font-size: ${em(12)};
  }
`

const CategoryLink = styled(Link)`
  padding: ${em(14)} ${em(18)};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${em(8)};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.midnatt};
  outline-offset: ${em(4)};

  &:hover {
    background-color: ${({ theme }) => theme.colors.ljusbla};
  }

  span {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)};

    font-size: ${em(16)};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: uppercase;
  }

  svg {
    width: ${em(32)};
    height: ${em(32)};
    flex-shrink: 0;
  }
`
