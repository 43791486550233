import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { em, hideVisually } from "polished"
import {
  Meta,
  ImagesCarousel,
  Infoblocks,
  Current,
  Expandable,
  Link,
} from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import Map from "../components/map"
import Infobox from "../components/infobox"
import Species from "../components/species"
import SitesCarousel from "../components/sites-carousel"
import CardsCarousel from "../components/cards-carousel"
import PostCard from "../components/post-card"
import Button from "../components/button"
import Styled from "../components/styled"
import {
  Heading1,
  Heading3,
  Heading4,
  Heading6,
} from "../components/styled/heading"
import CategorySvg from "../components/category-svg"
import { ReactComponent as SvgLocationMarker } from "../assets/images/icons/location-marker.svg"

export default function SiteTemplate({ data: { translations, config, site } }) {
  const mapQuery = `site_with_neighbours=${site.id}`
  // const mapQuery = [
  //   `site=${site.id}`,
  //   ...site.neighbours.map((n) => `site=${n.id}`),
  // ].join(`&`)

  const categoryGroups = [
    {
      id: `facilities`,
      title: translations.global.facilities,
      legacyType: [`facility_type`, `activity_type`],
    },
    {
      id: `accessibility`,
      title: translations.global.accessibility,
      legacyType: [`accessibility_type`],
    },
    // {
    //   id: `species`,
    //   title: translations.global.species,
    //   legacyType: [`animal`],
    // },
  ]
    .map((g) => ({
      ...g,
      categories: site.categories.filter((c) =>
        g.legacyType.includes(c.legacyType)
      ),
    }))
    .filter((g) => g.categories.length)

  const speciesCategories = site.categories.filter((c) =>
    [`animal`].includes(c.legacyType)
  )

  const buyFishingCardUrl = site.links.find(
    (l) => l.type == `booking_website`
  )?.url

  const AsideComponent = () => (
    <Aside>
      {!!site.images?.length && (
        <Images>
          <ImagesCarousel data={site.images} title={site.name} />
        </Images>
      )}

      <Ctas>
        {translations.global.buyFishingCard && buyFishingCardUrl && (
          <Button
            to={buyFishingCardUrl}
            target="_blank"
            size="large"
            foreground="white"
            background="hallon"
          >
            {translations.global.buyFishingCard}
          </Button>
        )}

        {!!site.markerPoint?.lat && !!site.markerPoint?.lng && (
          <Button
            to={`https://www.google.com/maps/dir/?api=1&destination=${site.markerPoint.lat},${site.markerPoint.lng}`}
            target="_blank"
            icon={<SvgLocationMarker />}
            size="large"
            foreground="midnatt"
            background="white"
          >
            {translations.global.directions}
          </Button>
        )}
      </Ctas>

      <Infobox
        data={{
          ...site,
          links: site.links.filter((l) => l.type != `booking_website`),
        }}
      />
    </Aside>
  )

  return (
    <Layout>
      <Container>
        <MapWrap>
          <h2 dangerouslySetInnerHTML={{ __html: translations.global.map }} />

          <Map
            query={mapQuery}
            preselectedSiteId={site.id}
            menu="fullscreen"
            scrollZoom="false"
            styleControl="fullscreen"
            zoomControl="true"
            measureControl="fullscreen"
            locationControl="fullscreen"
            printControl="fullscreen"
            legendControl="fullscreen"
          />
        </MapWrap>

        <Category>
          <Link to={site.mainCategory.path}>
            <CategorySvg id={site.mainCategory.id} aria-hidden="true" />

            <Heading3 as="span">{site.mainCategory.name}</Heading3>
          </Link>
        </Category>

        <Columns>
          <Main>
            <Content>
              <Heading1
                as="h1"
                dangerouslySetInnerHTML={{ __html: site.name }}
              />

              <Details>
                {categoryGroups.map((group) => (
                  <CategoryGroup key={group.id}>
                    <Heading6
                      dangerouslySetInnerHTML={{ __html: group.title }}
                    />

                    {group.id == `species` ? (
                      <Species categories={group.categories} />
                    ) : (
                      <Categories>
                        {group.categories.map((category) => (
                          <li key={category.id}>
                            <img
                              src={`https://assets.naturkartan.se/assets/categories/${category.id}/main.svg`}
                              alt=""
                              role="presentation"
                            />

                            <span
                              dangerouslySetInnerHTML={{
                                __html: category.name,
                              }}
                            />
                          </li>
                        ))}
                      </Categories>
                    )}
                  </CategoryGroup>
                ))}

                <Current data={site} Title={Heading4} Styled={Styled} />
              </Details>

              <AsideMobile>
                <AsideComponent />
              </AsideMobile>

              {site.description && (
                <Description>
                  <Expandable Toggle={ExpandableToggle}>
                    <Styled
                      $leadingParagraph={true}
                      dangerouslySetInnerHTML={{ __html: site.description }}
                    />
                  </Expandable>
                </Description>
              )}

              {!!speciesCategories.length && (
                <SpeciesWrap>
                  <Heading6
                    dangerouslySetInnerHTML={{
                      __html: translations.global.species,
                    }}
                  />

                  <Species categories={speciesCategories} />
                </SpeciesWrap>
              )}

              <InfoblocksStyled data={site} Title={Heading4} Styled={Styled} />
            </Content>
          </Main>

          <AsideDesktop>
            <AsideComponent />
          </AsideDesktop>
        </Columns>

        {!!site.neighbours.length && (
          <Related>
            <Heading4
              as="h2"
              dangerouslySetInnerHTML={{
                __html: translations.global.relatedPlaces,
              }}
            />

            <SitesCarousel
              sites={site.neighbours}
              siteHeadingNum={3}
              visibleSlides={3}
              contained={false}
            />
          </Related>
        )}

        {!!site.relatedArticles.length && (
          <Related>
            <Heading4
              as="h2"
              dangerouslySetInnerHTML={{
                __html: config.pages.articles.title,
              }}
            />

            <CardsCarousel visibleSlides={3}>
              {site.relatedArticles.map((a) => (
                <PostCard key={a.id} post={a} headingNum={3} />
              ))}
            </CardsCarousel>
          </Related>
        )}
      </Container>
    </Layout>
  )
}

export const Head = ({ data: { site } }) => {
  return (
    <Meta
      data={{
        title: site.name,
        socialImage: site.images[0]?.images.fallback.src,
        metaDescription: site.metaDescription,
      }}
    />
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    translations: translationsJson {
      global {
        map
        species
        facilities
        accessibility
        directions
        relatedPlaces
        buyFishingCard
      }
    }

    config: configJson {
      pages {
        articles {
          title
        }
      }
    }

    site: sitesJson(id: { eq: $id }) {
      id: nk_id
      path
      name
      description
      metaDescription: meta_description
      type
      theType: the_type
      difficultyText: difficulty_text
      openingHours: opening_hours {
        open
        close
      }
      markerPoint: marker_point {
        lat
        lng
      }
      facts
      directions
      address
      telephone
      email
      length
      time
      surface
      hills
      vegetation
      current
      parking
      communications
      regulations
      mainCategory: main_category {
        id: nk_id
        name
        path: _type_path
      }
      categories {
        id: nk_id
        slug
        name
        legacyType: legacy_type
      }
      links {
        url
        text
        type
      }
      files {
        url
        text
      }
      images {
        ...NKimageFragment
      }
      neighbours {
        ...SitesCardFragment
      }
      relatedArticles: related_articles(limit: 9) {
        ...ArticlesCardFragment
      }
    }
  }
`

const Container = styled.article`
  padding-bottom: ${em(120)};
  background-image: ${({ theme }) => theme.helpers.waveUrl(theme.colors.white)};
  background-size: 105% ${em(480)};
  background-position: center bottom ${em(-40)};
  background-repeat: no-repeat;

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-bottom: ${em(40)};
  }
`

const Columns = styled.div`
  ${({ theme }) => theme.grid.container(`narrow`)}

  margin-top: ${em(50)};
  display: grid;
  grid-template-columns: minmax(0, 2fr) minmax(${em(240)}, 1fr);
  column-gap: ${em(80)};
  row-gap: ${em(30)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${em(30)};
    grid-template-columns: 1fr;
  }
`

const Main = styled.div``

const AsideDesktop = styled.div`
  @media ${({ theme }) => theme.mq.mediumDown} {
    display: none;
  }
`

const AsideMobile = styled.div`
  margin-top: ${em(30)};

  @media ${({ theme }) => theme.mq.mediumUp} {
    display: none;
  }
`

const Aside = styled.aside`
  display: flex;
  flex-direction: column;
  gap: ${em(30)};
`

const Ctas = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${em(10)};
`

const Content = styled.div`
  width: 100%;
`

const CategoryGroup = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${em(12)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: ${em(14)};
  }
`

const Categories = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: ${em(20)};

  li {
    display: flex;
    align-items: center;
    gap: ${em(10)};
  }

  img {
    width: ${em(24)};
    flex-shrink: 0;
  }

  span {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)};

    font-size: ${em(14)};
  }
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${em(40)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    gap: ${em(20)};
  }

  &:not(:empty) {
    margin-top: ${em(30)};

    @media ${({ theme }) => theme.mq.mediumDown} {
      margin-top: ${em(20)};
    }
  }
`

const Description = styled.div`
  margin-top: ${em(40)};
  padding-top: ${em(40)};
  border-top: 1px solid ${({ theme }) => theme.colors.lightBorder};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${em(20)};
    padding-top: 0;
    border-top: 0;
  }
`

const SpeciesWrap = styled.section`
  margin-top: ${em(40)};
  display: flex;
  flex-direction: column;
  gap: ${em(12)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: ${em(14)};
  }
`

const MapWrap = styled.section`
  height: 60vh;
  min-height: ${em(320)};
  max-height: ${em(480)};
  position: relative;

  @media ${({ theme }) => theme.mq.mediumDown} {
    min-height: ${em(180)};
  }

  > h2 {
    ${hideVisually()}
  }
`

const Category = styled.div`
  ${({ theme }) => theme.grid.container(`narrow`)};

  display: flex;

  a {
    padding: ${em(12)} ${em(24)};
    display: flex;
    gap: ${em(12)};
    outline-offset: ${em(6)};
    color: ${({ theme }) => theme.colors.ljusbla};
    background-color: ${({ theme }) => theme.colors.midnatt};

    &:hover {
      background-color: ${({ theme }) => theme.colors.mellanbla};
    }
  }

  svg {
    width: ${em(24)};
    height: ${em(24)};
    flex-shrink: 0;
  }

  span {
    position: relative;
    bottom: ${em(-2)};
  }
`

const Related = styled.section`
  ${({ theme }) => theme.grid.container()}

  padding-top: ${em(100)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-top: ${em(40)};
  }

  ${Heading4} {
    margin-bottom: ${em(20)};
  }
`

const Images = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1;
  overflow: hidden;
`

const InfoblocksStyled = styled(Infoblocks)`
  margin-top: ${em(50)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${em(30)};
  }
`

const ExpandableToggle = styled.button`
  ${({ theme }) => theme.fonts.set(`primary`, `bold`)};

  width: 100%;
  padding: ${em(10)} ${em(20)};
  text-align: center;
  background-color: ${({ theme }) => theme.colors.white};

  &:hover {
    opacity: 0.8;
  }
`
