import React, { useState, useMemo, useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { em, hideVisually } from "polished"
import { intersection } from "lodash"

import SitesGrid from "../sites-grid"
import CategoriesFilter from "../categories-filter"
import SpeciesFilter from "../species-filter"
import Map from "../map"
import Styled from "../styled"
import { typesCategoriesPreselected } from "../../config/type"

const collectCategoriesAndSpecies = (sites) => {
  const categories = {}

  sites.forEach((site) => {
    if (site.mainCategory) categories[site.mainCategory.id] = site.mainCategory
  })

  return Object.values(categories)
}

const collectSpecies = (sites, selectedCategories) => {
  const species = {}

  sites.forEach((site) => {
    if (
      !selectedCategories.length ||
      site.categories.find((c) => selectedCategories.includes(c.id))
    )
      site.categories
        .filter((c) => c.legacyType == `animal`)
        .forEach((c) => (species[c.id] = c))
  })

  return Object.values(species)
}

const filterSites = (sites, selectedCategories, selectedSpecies) => {
  if (!selectedCategories.length && !selectedSpecies.length) return sites

  return sites.filter((site) => {
    let includeCategories = !selectedCategories.length
    let includeSpecies = !selectedSpecies.length

    if (selectedCategories.length) {
      if (site.mainCategory)
        includeCategories = selectedCategories.includes(site.mainCategory.id)

      if (!includeCategories)
        includeCategories = !!site.categories.find((c) =>
          selectedCategories.includes(c.id)
        )
    }

    if (selectedSpecies.length)
      includeSpecies = !!site.categories.find((c) =>
        selectedSpecies.includes(c.id)
      )

    return includeCategories && includeSpecies
  })
}

export default function Sites({ sites, type, municipality, afterMap }) {
  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          map
          noContent
        }
      }
    }
  `)

  const configCategoriesPreselected = type
    ? typesCategoriesPreselected[type.name]
    : []

  const categories = useMemo(() => collectCategoriesAndSpecies(sites), [sites])

  const getCategoriesIds = () => categories.map((c) => c.id)
  const preselectedCategories = configCategoriesPreselected.length
    ? intersection(configCategoriesPreselected, getCategoriesIds())
    : getCategoriesIds()
  const [selectedCategories, setSelectedCategories] = useState(
    preselectedCategories
  )
  const [selectedSpecies, setSelectedSpecies] = useState([])

  const species = useMemo(
    () => collectSpecies(sites, selectedCategories),
    [sites, selectedCategories]
  )

  const sitesFiltered = useMemo(
    () => filterSites(sites, selectedCategories, selectedSpecies),
    [sites, selectedCategories, selectedSpecies]
  )

  const mapLoadedRef = useRef(false)
  const categoriesForMapLoad = useRef(null)

  useEffect(() => {
    if (!species.length && selectedSpecies.length) setSelectedSpecies([])
  }, [species, selectedSpecies])

  const mapQueryChange = (query) => {
    const mapCategories = []
    const mapSpecies = []

    query.forEach((q) => {
      if ([`category`, `category_animal`].includes(q.type)) {
        if (categories.find((c) => c.id == q.value)) mapCategories.push(q.value)
        else if (species.find((c) => c.id == q.value)) mapSpecies.push(q.value)
      }
    })

    setSelectedCategories(mapCategories)
    setSelectedSpecies(mapSpecies)
  }

  const mapLoad = () => {
    mapLoadedRef.current = true
    if (Array.isArray(categoriesForMapLoad.current))
      setSelectedCategories(categoriesForMapLoad.current)
  }

  const categoriesFilterSelect = (categories) => {
    if (mapLoadedRef.current) setSelectedCategories(categories)
    else categoriesForMapLoad.current = categories
  }

  const speciesFilterSelect = (species) => {
    setSelectedSpecies(species)
  }

  const mapQuery = [
    `guide=${process.env.GATSBY_NATURKARTAN_GUIDE_ID}`,
    municipality && `municipality=${municipality.id}`,
    ...selectedCategories.map((c) => `category=${c}`),
    ...selectedSpecies.map((s) => `category_animal=${s}`),
  ]
    .filter(Boolean)
    .join(`&`)

  const EmptyComponent = () => (
    <Empty>
      <p
        dangerouslySetInnerHTML={{
          __html: translations.global.noContent,
        }}
      />
    </Empty>
  )

  return (
    <Container>
      <MapWrap>
        <h2 dangerouslySetInnerHTML={{ __html: translations.global.map }} />

        <Map
          onLoad={mapLoad}
          onQueryChange={mapQueryChange}
          query={mapQuery}
          disableAutoload={false}
          menu="fullscreen"
          scrollZoom="false"
          styleControl="fullscreen"
          zoomControl="true"
          measureControl="fullscreen"
          locationControl="fullscreen"
          printControl="fullscreen"
          legendControl="true"
          legend="true"
          zoom={8}
        />
      </MapWrap>

      {afterMap}

      {sites.length ? (
        <Inner>
          <Filters>
            {categories.length > 1 && (
              <div className="--categories">
                <CategoriesFilter
                  categories={categories}
                  selectedCategories={selectedCategories}
                  onSelectCategories={categoriesFilterSelect}
                  noneWhenAll={false}
                  contained={false}
                />
              </div>
            )}

            <div className="--species">
              <SpeciesFilter
                species={species}
                selectedSpecies={selectedSpecies}
                onSelectSpecies={speciesFilterSelect}
              />
            </div>
          </Filters>

          {sitesFiltered.length ? (
            <SitesGrid sites={sitesFiltered} siteHeadingNum={2} />
          ) : (
            <EmptyComponent />
          )}
        </Inner>
      ) : (
        <EmptyComponent />
      )}
    </Container>
  )
}

const Container = styled.div``

const Inner = styled.div`
  padding: ${em(70)} 0 ${em(150)};
  display: flex;
  flex-direction: column;
  gap: ${em(40)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding: ${em(30)} 0 ${em(60)};
    gap: ${em(20)};
  }
`

const Filters = styled.div`
  ${({ theme }) => theme.grid.container()}

  display: flex;
  align-items: center;
  gap: ${em(30)};

  @media ${({ theme }) => theme.mq.largeDown} {
    gap: ${em(26)};
  }

  @media ${({ theme }) => theme.mq.smallDown} {
    display: block;
  }

  > .--categories {
    min-width: 0;
  }

  > .--species {
    @media ${({ theme }) => theme.mq.smallUp} {
      min-width: ${em(190)};
      max-width: ${em(250)};
      flex-basis: 27%;
    }

    @media ${({ theme }) => theme.mq.smallDown} {
      margin-top: ${em(10)};
    }
  }
`

const Empty = styled(Styled)`
  ${({ theme }) => theme.grid.container()}

  padding-top: ${em(100)};
  padding-bottom: ${em(100)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-top: ${em(40)};
    padding-bottom: ${em(40)};
  }
`

const MapWrap = styled.div`
  height: 60vh;
  min-height: ${em(220)};
  max-height: ${em(640)};
  position: relative;

  > h2 {
    ${hideVisually()}
  }
`
