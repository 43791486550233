import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { em } from "polished"

import Button from "../button"
import CardsCarousel from "../cards-carousel"
import MunicipalityCard from "../municipality-card"
import { Heading2 } from "../styled/heading"
import { ReactComponent as SvgArrowRight } from "../../assets/images/icons/arrow-right.svg"

export default function MunicipalitiesCarousel({ theme = `light` }) {
  const { translations, config, municipalities } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          seeAll
        }
      }

      config: configJson {
        pages {
          municipalities {
            title
            slug
          }
        }
      }

      municipalities: allMunicipalitiesJson(
        filter: { sites_count: { gt: 0 } }
        sort: { sites_count: DESC }
      ) {
        nodes {
          ...MunicipalitiesCardFragment
        }
      }
    }
  `)

  if (!municipalities.nodes.length) return null

  return (
    <Container $theme={theme}>
      <Inner>
        <Header>
          <Heading>
            <Heading2
              as="span"
              dangerouslySetInnerHTML={{
                __html: config.pages.municipalities.title,
              }}
            />
          </Heading>
        </Header>

        <CardsCarousel visibleSlides={4}>
          {municipalities.nodes.map((m) => (
            <MunicipalityCard
              key={m.id}
              municipality={m}
              headingNum={3}
              hideOutline={true}
            />
          ))}
        </CardsCarousel>

        <More>
          <Button
            to={`/${config.pages.municipalities.slug}`}
            icon={<SvgArrowRight />}
            iconpos="right"
            display="anchor"
            foreground="hallon"
          >
            {translations.global.seeAll}
          </Button>
        </More>
      </Inner>
    </Container>
  )
}

const Inner = styled.div`
  ${({ theme }) => theme.grid.container()}
`

const Header = styled.header`
  margin-bottom: ${em(40)};
`

const Heading = styled.h2``

const More = styled.div`
  margin-top: ${em(20)};
  text-transform: uppercase;

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${em(12)};
  }
`

const Container = styled.section`
  padding-top: ${em(130)};
  padding-bottom: ${em(130)};
  background-color: ${({ theme, $theme }) =>
    theme.colors[{ light: `ljusbla`, dark: `morkbla` }[$theme]]};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-top: ${em(40)};
    padding-bottom: ${em(40)};
  }

  ${Heading} {
    color: ${({ theme, $theme }) =>
      theme.colors[{ light: `foreground`, dark: `white` }[$theme]]};
  }
`
