import styled, { css } from "styled-components"
import { em } from "polished"

import { paragraphSmallStyles } from "./paragraph"

const figureStyles = css`
  &.--with-background {
    padding: ${em(40)};
    background-color: ${({ theme }) => theme.colors.white};

    @media ${({ theme }) => theme.mq.mediumDown} {
      padding: ${em(20)};
    }
  }

  figcaption {
    ${paragraphSmallStyles}

    margin-top: ${em(10)};
  }
`

const Figure = styled.figure`
  ${figureStyles}
`

export { Figure, figureStyles }
