import React from "react"
import styled from "styled-components"
import { em } from "polished"

export default function CardsGrid({ children, cols = 3 }) {
  if (!Array.isArray(children) || !children.length) return null

  return (
    <Container $cols={cols}>
      {children.map((card) => (
        <li key={card.key}>{card}</li>
      ))}
    </Container>
  )
}

const Container = styled.ul`
  display: grid;
  grid-template-columns: repeat(${({ $cols }) => $cols}, minmax(0, 1fr));
  gap: ${em(26)};

  @media ${({ theme }) => theme.mq.xlargeDown} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media ${({ theme }) => theme.mq.mediumDown} {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  > li {
    display: flex;
  }
`
