import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { em, hideVisually } from "polished"
import { Meta, markdown } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import Sites from "../components/sites"
import PostsHighlights from "../components/posts-highlights"
import CardsGrid from "../components/cards-grid"
import CardsCarousel from "../components/cards-carousel"
import MunicipalitiesCarousel from "../components/municipalities-carousel"
import PostCard from "../components/post-card"
import EventCard from "../components/event-card"
import Section from "../components/section"

export default function TypeTemplate({
  data: {
    translations,
    config,
    sites,
    latestArticles,
    latestEvents,
    latestNews,
  },
}) {
  const type = config.types[0]

  return (
    <Layout>
      <Container>
        <Heading>{type.title}</Heading>

        {!!type.description && (
          <Description
            dangerouslySetInnerHTML={{
              __html: markdown(type.description),
            }}
          />
        )}

        <Sites type={type} sites={sites.nodes} />

        <MunicipalitiesCarousel theme="dark" />

        {(!!latestNews.nodes.length ||
          !!latestEvents.nodes.length ||
          !!latestArticles.nodes.length) && (
          <Highlights>
            {!!latestNews.nodes.length && (
              <Section
                heading={config.pages.news.title}
                headingNum={2}
                moreText={translations.global.seeAll}
                moreLink={`/${config.pages.news.slug}`}
                invertColors={true}
              >
                <CardsCarousel visibleSlides={3}>
                  {latestNews.nodes.map((news) => (
                    <PostCard key={news.id} post={news} headingNum={3} />
                  ))}
                </CardsCarousel>
              </Section>
            )}

            {!!latestEvents.nodes.length && (
              <Section
                heading={config.pages.events.title}
                headingNum={2}
                moreText={translations.global.seeAll}
                moreLink={`/${config.pages.events.slug}`}
                invertColors={true}
              >
                <CardsGrid cols={3}>
                  {latestEvents.nodes.map((event) => (
                    <EventCard key={event.id} event={event} headingNum={3} />
                  ))}
                </CardsGrid>
              </Section>
            )}

            {!!latestArticles.nodes.length && (
              <Section
                heading={config.pages.articles.title}
                headingNum={2}
                moreText={translations.global.seeAll}
                moreLink={`/${config.pages.articles.slug}`}
                invertColors={true}
              >
                <PostsHighlights
                  posts={latestArticles.nodes}
                  postHeadingNum={3}
                />
              </Section>
            )}
          </Highlights>
        )}
      </Container>
    </Layout>
  )
}

export const Head = ({ data: { config, img } }) => {
  const type = config.types[0]

  return (
    <Meta
      data={{
        title: type.title,
        description: type.tagline,
        socialImage: img?.childImageSharp.social.images.fallback.src,
      }}
    />
  )
}

export const pageQuery = graphql`
  query ($type: String!, $categories: [String]!) {
    translations: translationsJson {
      global {
        seeAll
      }
    }

    config: configJson {
      types(name: $type) {
        name
        slug
        title
        tagline
        description
      }

      pages {
        articles {
          title
          slug
        }

        events {
          title
          slug
        }

        news {
          title
          slug
        }
      }
    }

    sites: allSitesJson(
      filter: { categories: { elemMatch: { nk_id: { in: $categories } } } }
      sort: { name: ASC }
    ) {
      nodes {
        ...SitesCardFragment
      }
    }

    latestArticles: allArticlesJson(
      filter: { categories: { elemMatch: { nk_id: { in: $categories } } } }
      sort: { publish_at: DESC }
      limit: 3
    ) {
      nodes {
        ...ArticlesCardFragment
      }
    }

    latestEvents: allEventsJson(
      filter: { categories: { elemMatch: { nk_id: { in: $categories } } } }
      sort: { occurs_at: ASC }
      limit: 6
    ) {
      nodes {
        ...EventsCardFragment
      }
    }

    latestNews: allNewsJson(
      filter: { categories: { elemMatch: { nk_id: { in: $categories } } } }
      sort: { publish_at: DESC }
      limit: 6
    ) {
      nodes {
        ...NewsCardFragment
      }
    }
  }
`

const Container = styled.div``

const Highlights = styled.div`
  padding: ${em(100)} 0 ${em(150)};
  background-color: ${({ theme }) => theme.colors.midnatt};
  background-image: ${({ theme }) =>
    theme.helpers.scalesPatternUrl(theme.colors.morkbla)};
  background-size: 70%;
  background-position: center center;
  background-repeat: repeat;

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding: ${em(40)} 0 ${em(60)};
    background-size: 120%;
  }

  > * + * {
    margin-top: ${em(150)};

    @media ${({ theme }) => theme.mq.mediumDown} {
      margin-top: ${em(60)};
    }
  }
`

const Heading = styled.h1`
  ${hideVisually()}
`

const Description = styled.div`
  ${hideVisually()}
`
