import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { em, hideVisually } from "polished"
import {
  Meta,
  Search,
  SearchResult,
} from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import SearchForm from "../components/search-form"
import { Heading1, Heading3, Heading4 } from "../components/styled/heading"
import { Paragraph, ParagraphSmall } from "../components/styled/paragraph"

export default function SearchTemplate({
  params: { "*": query },
  data: { site, translations, image },
}) {
  const [inputValue, setInputValue] = useState(query)

  useEffect(() => {
    setInputValue(query)
  }, [query])

  return (
    <Layout>
      {image && (
        <Image>
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt={translations.search.title}
          />
        </Image>
      )}

      <Container>
        <div>
          <Heading1>{translations.search.title}</Heading1>

          <SearchFormWrap>
            <SearchForm
              inputProps={{
                value: inputValue,
                placeholder: `${translations.search.title} ${site.siteMetadata.name}`,
                onChange: (e) => setInputValue(e.target.value),
              }}
            />
          </SearchFormWrap>

          <Search query={query}>
            {({ status, total, results, page, hasNextPage, showNextPage }) => (
              <>
                {status == `results` && (
                  <Info>
                    <strong>{total}</strong>
                    {` `}
                    {translations.search.results?.toLowerCase()}
                  </Info>
                )}

                {status == `searching` && (
                  <Loader>
                    <span>{translations.search.loadingResults}</span>

                    {Array.from({ length: 3 }).map((_, i) => (
                      <div key={i} />
                    ))}
                  </Loader>
                )}

                {[`results`, `error`].includes(status) && total <= 0 && (
                  <Empty>{translations.search.noResults}</Empty>
                )}

                {status == `results` && total > 0 && (
                  <Results>
                    {results.map((result) => (
                      <SearchResult
                        key={`${result.type}-${result.id}`}
                        data={result}
                        TitleStyled={Heading3}
                        LabelStyled={Heading4}
                        DescriptionStyled={Paragraph}
                      />
                    ))}
                  </Results>
                )}

                {hasNextPage && (
                  <More>
                    <button type="button" onClick={showNextPage}>
                      {translations.search.loadMoreResults}
                    </button>
                  </More>
                )}
              </>
            )}
          </Search>
        </div>
      </Container>
    </Layout>
  )
}

export const Head = ({ query, data: { translations } }) => {
  return (
    <Meta
      data={{
        title: query
          ? `${translations.search.title} “${query}”`
          : `${translations.search.title}`,
        canonical: `/${translations.search.slug}/`,
      }}
    />
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        name
      }
    }

    translations: translationsJson {
      search {
        title
        slug
        results
        noResults
        loadingResults
        loadMoreResults
      }
    }

    image: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "content/type/fishing.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

const Image = styled.div`
  width: 100%;
  height: clamp(${em(120)}, 24vh, ${em(280)});
  background-color: ${({ theme }) => theme.colors.imageBackground};

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

const Container = styled.div`
  min-height: 50vh;
  padding-top: ${em(40)};
  padding-bottom: ${em(80)};
  background-image: ${({ theme }) => theme.helpers.waveUrl(theme.colors.white)};
  background-size: 105% ${em(480)};
  background-position: center bottom ${em(-40)};
  background-repeat: no-repeat;

  > div {
    ${({ theme }) => theme.grid.container(`xnarrow`)}
  }
`

const SearchFormWrap = styled.div`
  margin-top: ${em(24)};
`

const Info = styled(ParagraphSmall)`
  margin-top: ${em(20)};
  color: ${({ theme }) => theme.colors.mellanbla};
  text-align: right;
`

const Loader = styled.div`
  margin-top: ${em(30)};
  display: flex;
  flex-direction: column;
  gap: ${em(30)};

  div {
    height: ${em(180)};
    background-color: ${({ theme }) => theme.colors.white};
    animation: ${({ theme }) => theme.animations.fadeBlink} 1s
      ${({ theme }) => theme.easings.default} infinite;

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }
  }

  span {
    ${hideVisually()}
  }
`

const Empty = styled(Paragraph)`
  margin-top: ${em(30)};
`

const Results = styled.div`
  margin-top: ${em(50)};
  display: flex;
  flex-direction: column;
  gap: ${em(30)};
`

const More = styled.div`
  padding-top: ${em(20)};
  display: flex;
  justify-content: center;

  button {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)};

    padding: ${em(10)} ${em(30)};
    background-color: ${({ theme }) => theme.colors.hallon};
    color: ${({ theme }) => theme.colors.white};
    font-size: ${em(16)};
    text-transform: uppercase;

    &:hover {
      opacity: 0.8;
    }
  }
`
