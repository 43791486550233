import React from "react"
import { graphql } from "gatsby"
import { Meta } from "@outdoormap/gatsby-plugin-brand-sites"
import styled from "styled-components"
import { em } from "polished"

import Layout from "../components/layout"
import Styled from "../components/styled"
import MunicipalityCard from "../components/municipality-card"
import { Heading1 } from "../components/styled/heading"

export default function MunicipalitiesTemplate({
  data: { translations, config, municipalities },
}) {
  return (
    <Layout>
      <Container>
        <Inner>
          <Heading>
            <Heading1 as="span">{config.pages.municipalities.title}</Heading1>
          </Heading>

          {municipalities.nodes.length ? (
            <Municipalities>
              {municipalities.nodes.map((municipality) => (
                <MunicipalityCard
                  key={municipality.id}
                  municipality={municipality}
                  headingNum={2}
                />
              ))}
            </Municipalities>
          ) : (
            <Empty>
              <p
                dangerouslySetInnerHTML={{
                  __html: translations.global.noContent,
                }}
              />
            </Empty>
          )}
        </Inner>
      </Container>
    </Layout>
  )
}

export const Head = ({ data: { config } }) => {
  return <Meta data={{ title: config.pages.municipalities.title }} />
}

export const pageQuery = graphql`
  query {
    translations: translationsJson {
      global {
        noContent
      }
    }

    config: configJson {
      pages {
        municipalities {
          title
        }
      }
    }

    municipalities: allMunicipalitiesJson(
      filter: { sites_count: { gt: 0 } }
      sort: { sites_count: DESC }
    ) {
      nodes {
        ...MunicipalitiesCardFragment
      }
    }
  }
`

const Container = styled.div`
  padding-top: ${em(100)};
  padding-bottom: ${em(100)};
  background-color: ${({ theme }) => theme.colors.morkbla};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-top: ${em(40)};
    padding-bottom: ${em(40)};
  }
`

const Inner = styled.div`
  ${({ theme }) => theme.grid.container()}
`

const Heading = styled.h1`
  margin-bottom: ${em(40)};
  color: ${({ theme }) => theme.colors.white};

  @media ${({ theme }) => theme.mq.smallDown} {
    margin-bottom: ${em(16)};
  }
`

const Municipalities = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: ${em(26)};
  row-gap: ${em(40)};

  @media ${({ theme }) => theme.mq.largeDown} {
    grid-template-columns: repeat(3, 1fr);
    gap: ${em(14)};
    row-gap: ${em(26)};
  }

  @media ${({ theme }) => theme.mq.smallDown} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${({ theme }) => theme.mq.xsmallDown} {
    grid-template-columns: 1fr;
    row-gap: ${em(14)};
  }
`

const Empty = styled(Styled)``
