import React from "react"
import { graphql } from "gatsby"
import styled, { css } from "styled-components"
import { hideVisually, em } from "polished"
import { Meta } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import Map from "../components/map"
import FeaturedCategories from "../components/featured-categories"
import PostsHighlights from "../components/posts-highlights"
import CardsGrid from "../components/cards-grid"
import CardsCarousel from "../components/cards-carousel"
import MunicipalitiesCarousel from "../components/municipalities-carousel"
import EventCard from "../components/event-card"
import PostCard from "../components/post-card"
import Section from "../components/section"

export default function IndexPage({
  data: {
    config,
    translations,
    latestArticles,
    latestEvents,
    latestNews,
    latestTours,
  },
}) {
  return (
    <Layout>
      <Heading>{config.pages.index.hero.title}</Heading>

      <MapWrap>
        <h2 dangerouslySetInnerHTML={{ __html: translations.global.map }} />

        <Map
          query={[`guide=${process.env.GATSBY_NATURKARTAN_GUIDE_ID}`].join(`&`)}
          menu="fullscreen"
          scrollZoom="false"
          styleControl="fullscreen"
          zoomControl="true"
          measureControl="fullscreen"
          locationControl="fullscreen"
          printControl="fullscreen"
          legendControl="true"
          legend="true"
          zoom={8}
        />
      </MapWrap>

      <FeaturedCategories />

      <MunicipalitiesCarousel />

      {!!latestTours.nodes.length && (
        <Highlights>
          <Section
            heading={config.pages.tours.title}
            headingNum={2}
            moreText={translations.global.seeAll}
            moreLink={`/${config.pages.tours.slug}`}
          >
            <CardsCarousel visibleSlides={2}>
              {latestTours.nodes.map((tour) => (
                <PostCard
                  key={tour.id}
                  post={tour}
                  headingNum={3}
                  showCta={true}
                />
              ))}
            </CardsCarousel>
          </Section>
        </Highlights>
      )}

      {(!!latestArticles.nodes.length ||
        !!latestNews.nodes.length ||
        !!latestEvents.nodes.length) && (
        <Highlights $invertedColors="true">
          {!!latestArticles.nodes.length && (
            <Section
              heading={config.pages.articles.title}
              headingNum={2}
              moreText={translations.global.seeAll}
              moreLink={`/${config.pages.articles.slug}`}
              invertColors={true}
            >
              <PostsHighlights
                posts={latestArticles.nodes}
                postHeadingNum={3}
              />
            </Section>
          )}

          {!!latestNews.nodes.length && (
            <Section
              heading={config.pages.news.title}
              headingNum={2}
              moreText={translations.global.seeAll}
              moreLink={`/${config.pages.news.slug}`}
              invertColors={true}
            >
              <CardsCarousel visibleSlides={3}>
                {latestNews.nodes.map((news) => (
                  <PostCard key={news.id} post={news} headingNum={3} />
                ))}
              </CardsCarousel>
            </Section>
          )}

          {!!latestEvents.nodes.length && (
            <Section
              heading={config.pages.events.title}
              headingNum={2}
              moreText={translations.global.seeAll}
              moreLink={`/${config.pages.events.slug}`}
              invertColors={true}
            >
              <CardsGrid cols={3}>
                {latestEvents.nodes.map((event) => (
                  <EventCard key={event.id} event={event} headingNum={3} />
                ))}
              </CardsGrid>
            </Section>
          )}
        </Highlights>
      )}
    </Layout>
  )
}

export const Head = ({ data: { config } }) => {
  return (
    <Meta
      data={{
        title: config.pages.index.title,
        titleOverridePattern: true,
      }}
    />
  )
}

export const pageQuery = graphql`
  query {
    translations: translationsJson {
      global {
        map
        seeAll
      }
    }

    config: configJson {
      pages {
        index {
          title
          hero {
            title
          }
        }
        articles {
          title
          slug
        }
        news {
          title
          slug
        }
        events {
          title
          slug
        }
        tours {
          title
          slug
        }
      }

      types {
        name
        slug
        title
        tagline
      }
    }

    latestNews: allNewsJson(sort: { publish_at: DESC }, limit: 6) {
      nodes {
        ...NewsCardFragment
      }
    }

    latestEvents: allEventsJson(sort: { occurs_at: ASC }, limit: 6) {
      nodes {
        ...EventsCardFragment
      }
    }

    latestArticles: allArticlesJson(sort: { publish_at: DESC }, limit: 3) {
      nodes {
        ...ArticlesCardFragment
      }
    }

    latestTours: allToursJson(sort: { publish_at: DESC }, limit: 6) {
      nodes {
        ...ToursCardFragment
      }
    }
  }
`

const Heading = styled.h1`
  ${hideVisually()}
`

const MapWrap = styled.section`
  height: 60vh;
  min-height: ${em(220)};
  max-height: ${em(640)};

  > h2 {
    ${hideVisually()}
  }
`

const Highlights = styled.div`
  padding: ${em(100)} 0 ${em(150)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding: ${em(40)} 0 ${em(60)};
  }

  ${({ $invertedColors }) =>
    $invertedColors &&
    css`
      background-color: ${({ theme }) => theme.colors.midnatt};
      background-image: ${({ theme }) =>
        theme.helpers.scalesPatternUrl(theme.colors.morkbla)};
      background-size: 70%;
      background-position: center center;
      background-repeat: repeat;

      @media ${({ theme }) => theme.mq.mediumDown} {
        background-size: 120%;
      }
    `}

  ${({ $waveBackground }) =>
    $waveBackground &&
    css`
      background-image: ${({ theme }) =>
        theme.helpers.waveUrl(theme.colors.white)};
      background-size: 105% ${em(480)};
      background-position: center bottom ${em(-40)};
      background-repeat: no-repeat;
    `}

  > * + * {
    margin-top: ${em(150)};

    @media ${({ theme }) => theme.mq.mediumDown} {
      margin-top: ${em(60)};
    }
  }
`
