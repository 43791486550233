import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { em } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import { Heading2, Heading3 } from "../styled/heading"
import { ReactComponent as SvgComment } from "../../assets/images/icons/comment.svg"
import { ReactComponent as SvgCommunicationPhone } from "../../assets/images/icons/communication-phone.svg"
import { ReactComponent as SvgMapFlag } from "../../assets/images/icons/map-flag.svg"
import { ReactComponent as SvgMapLocation } from "../../assets/images/icons/map-location.svg"

const ICONS = {
  mapLocation: <SvgMapLocation />,
  comment: <SvgComment />,
  mapFlag: <SvgMapFlag />,
  mobileMessage: <SvgCommunicationPhone />,
}

const SvgIcon = ({ name }) => ICONS[name]

export default function GoodToKnow() {
  const { config } = useStaticQuery(graphql`
    query {
      config: configJson {
        footer {
          goodToKnow: good_to_know {
            title
            items {
              url
              title
              icon
              hide
            }
          }
        }
      }
    }
  `)

  const items = config.footer.goodToKnow.items.filter((i) => i.hide != `true`)

  if (!items.length) return null

  return (
    <Container>
      <div>
        <Heading2
          as="h2"
          dangerouslySetInnerHTML={{ __html: config.footer.goodToKnow.title }}
        />

        <Links>
          {items.map((item, i) => (
            <li key={i}>
              <Link to={item.url}>
                <Icon>
                  <SvgIcon name={item.icon} aria-hidden="true" />
                </Icon>

                <Heading3
                  as="span"
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
              </Link>
            </li>
          ))}
        </Links>
      </div>
    </Container>
  )
}

const Container = styled.section`
  padding: ${em(150)} 0;
  color: ${({ theme }) => theme.colors.ljusbla};
  background-color: ${({ theme }) => theme.colors.mellanbla};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding: ${em(50)} 0;
  }

  > div {
    ${({ theme }) => theme.grid.container()}
  }
`

const Icon = styled.div`
  width: ${em(70)};
  height: ${em(70)};
  margin: 0 auto;
  margin-bottom: ${em(15)};
  display: block;
  border-radius: 100%;
  color: ${({ theme }) => theme.colors.midnatt};
  background-color: ${({ theme }) => theme.colors.ljusbla};

  @media ${({ theme }) => theme.mq.largeDown} {
    width: ${em(80)};
    height: ${em(80)};
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
    padding: 26% 30%;
  }
`

const Links = styled.ul`
  margin-top: ${em(20)};
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.colors.ljusbla};

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: ${em(13)};
  }

  li {
    min-width: 25%;
    margin-top: -1px;
    margin-left: -1px;
    flex: 1;
    border: solid ${({ theme }) => theme.colors.ljusbla};
    border-width: 1px 0 0 1px;

    @media ${({ theme }) => theme.mq.largeDown} {
      min-width: 50%;
    }

    @media ${({ theme }) => theme.mq.xsmallDown} {
      min-width: 100%;
    }
  }

  a {
    height: 100%;
    padding: ${em(50)} ${em(20)};
    display: block;
    outline-offset: ${em(-10)};

    @media ${({ theme }) => theme.mq.largeDown} {
      padding: ${em(20)};
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.morkbla};
    }
  }
`
