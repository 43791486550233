import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { em } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import { Heading3 } from "../styled/heading"

export default function Menus() {
  const { config } = useStaticQuery(graphql`
    query {
      config: configJson {
        types {
          slug
          title
          name
        }

        footer {
          types
          explore {
            title
            items {
              url
              title
              hide
            }
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Group>
        <Heading3
          as="h2"
          dangerouslySetInnerHTML={{ __html: config.footer.types }}
        />

        <ul>
          {config.types.map((type, i) => (
            <li key={i}>
              <Link to={`/${type.slug}`}>{type.title}</Link>
            </li>
          ))}
        </ul>
      </Group>

      <Group $secondary={true}>
        <Heading3
          as="h2"
          dangerouslySetInnerHTML={{
            __html: config.footer.explore.title,
          }}
        />

        <ul>
          {config.footer.explore.items.map(
            (item, i) =>
              item.hide != `true` && (
                <li key={i}>
                  <Link to={item.url}>{item.title}</Link>
                </li>
              )
          )}
        </ul>
      </Group>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: ${em(20)};

  @media ${({ theme }) => theme.mq.xsmallDown} {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`

const Group = styled.section`
  ${Heading3} {
    margin-bottom: ${em(15)};
  }

  ul {
    column-count: 2;
    column-gap: ${em(30)};

    @media ${({ theme }) => theme.mq.xlargeDown} {
      column-count: 1;
    }
  }

  li {
    width: 100%;
    margin-bottom: ${em(10)};
    clear: both;
    display: inline-block;
    font-size: ${({ $secondary }) => em($secondary ? 16 : 18)};
    line-height: 1.2;

    @media ${({ theme }) => theme.mq.mediumDown} {
      font-size: ${({ $secondary }) => em($secondary ? 14 : 16)};
    }

    &:hover {
      opacity: 0.8;
    }
  }
`
