import { rgba } from "polished"

let colors = {
  black: `#000000`,
  white: `#ffffff`,
  midnatt: `#05132d`,
  morkbla: `#142139`,
  mellanbla: `#243d5c`,
  hallon: `#e02563`,
  hjortron: `#e09225`,
  himmelsbla: `#2079df`,
  ljusbla: `#e6f2fb`,
}

colors = {
  ...colors,
  // by function:
  background: colors.ljusbla,
  foreground: colors.midnatt,
  active: colors.hallon,
  alert: colors.hjortron,
  imageBackground: colors.hjortron,
  lightBorder: rgba(colors.midnatt, 0.2),
}

export default colors
