// site main category to type assignment (a category should only be assigned once):

const typesCategories = {
  planyourtrip: [
    `118`, // boat launch
    `55`, // accommodation
    `56`, // camping
    `26`, // fishing
    `229`, // fishing area
    `105`, // fishing permit
    `73`, // shop
    `74`, // rental
    `57`, // club-house
  ],
  // boatlaunch: [
  //   `118`, // boat launch
  // ],
  // accomodation: [
  //   `55`, // accommodation
  //   `56`, // camping
  // ],
  // fishing: [
  //   `26`, // fishing
  // ],
  // fishingarea: [
  //   `229`, // fishing area
  //   `105`, // fishing permit
  // ],
  // shop: [
  //   `73`, // shop
  //   `74`, // rental
  // ],
  // clubhouse: [
  //   `57`, // club-house
  // ],
  // // name: [] // empty for catch-all
}

// site categories to include in type pages:

const typesCategoriesIncluded = typesCategories
// const typesCategoriesIncluded = {}
// Object.keys(typesCategories).forEach(
//   (k) =>
//     (typesCategoriesIncluded[k] = [
//       `118`, // boat launch
//       `26`, // fishing
//       `229`, // fishing area
//       `105`, // fishing permit
//       `55`, // accommodation
//       `56`, // camping
//       `73`, // shop
//       `74`, // rental
//       `57`, // club-house
//     ])
// )

// const typesCategoriesIncluded = {
//   boatlaunch: [],
//   accomodation: [],
//   fishing: [],
//   fishingarea: [],
//   shop: [],
//   clubhouse: [],
// }

// site categories to preselect in type pages:

const typesCategoriesPreselected = typesCategories

// featured categories

const typesCategoriesFeatured = [`118`, `55`, `26`, `229`, `73`, `74`]

module.exports = {
  typesCategories,
  typesCategoriesIncluded,
  typesCategoriesPreselected,
  typesCategoriesFeatured,
}
