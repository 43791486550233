import { ReactComponent as SvgBoatlaunch } from "../../assets/images/icons/boatlaunch.svg"
import { ReactComponent as SvgAccomodation } from "../../assets/images/icons/accomodation.svg"
import { ReactComponent as SvgFishing } from "../../assets/images/icons/fishing.svg"
import { ReactComponent as SvgFishingarea } from "../../assets/images/icons/fishingarea.svg"
import { ReactComponent as SvgShop } from "../../assets/images/icons/shop.svg"
import { ReactComponent as SvgClubhouse } from "../../assets/images/icons/clubhouse.svg"

const icons = {
  boatlaunch: SvgBoatlaunch,
  accomodation: SvgAccomodation,
  fishing: SvgFishing,
  fishingarea: SvgFishingarea,
  shop: SvgShop,
  clubhouse: SvgClubhouse,
}

export default function TypeIconSvg({ name, ...props }) {
  if (!name) return null

  return icons[name](props)
}
