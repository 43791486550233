import React, { useMemo, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled, { css } from "styled-components"
import { em } from "polished"
import { chunk } from "lodash"

import Button from "../button"
import PostCard from "../post-card"
import useRefState from "../../utils/use-ref-state"

const PER_CHUNK = 3

export default function PostsHighlights({
  posts,
  postHeadingNum,
  chunksPerPage = (posts?.length || 0) * PER_CHUNK,
  shouldResetPage,
}) {
  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          showMore
        }
      }
    }
  `)

  const [page, pageRef, setPage] = useRefState(1)

  const postsChunks = useMemo(
    () => (posts?.length ? chunk(posts, PER_CHUNK) : []),
    [posts]
  )

  const hasMore = postsChunks.length > page * chunksPerPage

  const moreClick = () => {
    if (hasMore) setPage((p) => p + 1)
  }

  useEffect(() => {
    if (pageRef.current != 1) setPage(1)
  }, [shouldResetPage])

  if (!postsChunks.length) return null

  return (
    <div>
      {postsChunks.slice(0, page * chunksPerPage).map((chunk, i) => (
        <Highlights key={i} $reversed={(i + 1) % 2 == 0}>
          {chunk.map((post, ii) => (
            <li key={post.id}>
              <PostCard
                post={post}
                headingNum={postHeadingNum}
                major={ii == 0}
                showCta={true}
              />
            </li>
          ))}
        </Highlights>
      ))}

      {hasMore && (
        <More>
          <Button
            type="button"
            display="button"
            size="large"
            foreground="white"
            background="hallon"
            onClick={moreClick}
          >
            {translations.global.showMore}
          </Button>
        </More>
      )}
    </div>
  )
}

const Highlights = styled.ul`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: ${em(26)};

  ${({ $reversed }) =>
    $reversed &&
    css`
      transform: scale(-1, 1);

      > li {
        transform: scale(-1, 1);
      }
    `}

  @media ${({ theme }) => theme.mq.mediumDown} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${({ theme }) => theme.mq.xsmallDown} {
    grid-template-columns: 1fr;
  }

  & + & {
    margin-top: ${em(26)};
  }

  > li {
    &:first-child {
      grid-row: 1 / 3;

      @media ${({ theme }) => theme.mq.mediumDown} and ${({ theme }) =>
          theme.mq.xsmallUp} {
        grid-column: 1 / 3;
      }

      > * {
        @media ${({ theme }) => theme.mq.mediumUp} {
          min-height: 100%;
        }
      }
    }
  }
`

const More = styled.div`
  margin-top: ${em(40)};
  display: flex;
  justify-content: center;

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${em(24)};
  }
`
