import React, { useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { em } from "polished"
import { v4 as uuidv4 } from "uuid"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import CategorySvg from "../category-svg"
import languages from "../../config/languages"
import { ReactComponent as ChevronDownSvg } from "../../assets/images/icons/chevron-down.svg"
import { ReactComponent as SvgMagnifyingGlass } from "../../assets/images/icons/magnifying-glass.svg"
import useRefState from "../../utils/use-ref-state"

const ENABLE_SEARCH = !!process.env.GATSBY_TYPESENSE_KEY

const ButtonLink = ({ to, ...props }) =>
  to ? <Link to={to} {...props} /> : <button type="button" {...props} />

export default function Nav({ onSearchOpen }) {
  const { translations, config, categories } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        search {
          title
          slug
        }
      }

      config: configJson {
        types {
          title
        }

        header {
          nav {
            items {
              title
              # url
              items {
                title
                url
                hide
              }
            }
          }
        }
      }

      categories: allCategoriesJson(
        filter: { _featured: { eq: true } }
        sort: { name: ASC }
      ) {
        nodes {
          id: nk_id
          name
          path: _type_path
        }
      }
    }
  `)

  const [activeBase, activeBaseRef, setActiveBase] = useRefState(-1)
  const containerRef = useRef()
  const baseClassName = useRef(`a${uuidv4()}`)

  const langCurrent = languages.find((i) => i.code === process.env.GATSBY_LANG)
  const langSub = []
  languages.forEach(
    (lang) => lang.code !== langCurrent.code && langSub.push(lang)
  )

  const items = [
    {
      isType: true,
      title: config.types[0].title,
      items: categories.nodes.map((c) => ({
        id: c.id,
        url: c.path,
        title: c.name,
      })),
    },
    ...config.header.nav.items,
    {
      title: langCurrent.title,
      items: langSub,
    },
  ]

  const baseClick = (e) => {
    const index = parseInt(e.currentTarget.dataset.index)
    setActiveBase(activeBaseRef.current === index ? -1 : index)
  }

  const linkClick = () => setActiveBase(-1)

  const searchButtonClick = (e) => {
    e.preventDefault()
    // setSearchModalOpen(true)
    onSearchOpen()
  }

  const docClick = (e) => {
    if (activeBaseRef.current > -1 && !containerRef.current?.contains(e.target))
      setActiveBase(-1)
  }

  const winKeyup = (e) => {
    if (
      e.key === `Escape` &&
      activeBaseRef.current > -1 &&
      document.activeElement &&
      containerRef.current?.contains(document.activeElement)
    ) {
      document
        .querySelector(
          `.${baseClassName.current}[data-index="${activeBaseRef.current}"]`
        )
        ?.focus()

      setActiveBase(-1)
    }
  }

  // useEffect(() => {
  //   if (searchModalOpen && onSearchOpen) onSearchOpen()
  //   if (!searchModalOpen && onSearchClose) onSearchClose()
  // }, [searchModalOpen])

  useEffect(() => {
    if (typeof document !== `undefined`)
      document.addEventListener(`click`, docClick)

    if (typeof window !== `undefined`)
      window.addEventListener(`keyup`, winKeyup)

    return () => {
      if (typeof document !== `undefined`)
        document.addEventListener(`click`, docClick)

      if (typeof window !== `undefined`)
        window.removeEventListener(`keyup`, winKeyup)
    }
  }, [])

  return (
    <>
      <Container ref={containerRef}>
        {items.map((base, i) => (
          <li key={i} className={i === activeBase ? `--active` : undefined}>
            <ButtonLink
              to={base.url}
              onClick={baseClick}
              className={baseClassName.current}
              data-index={i}
              aria-haspopup={!!base.items}
              aria-expanded={i === activeBase}
            >
              <span dangerouslySetInnerHTML={{ __html: base.title }} />

              {base.items && <ChevronDownSvg />}
            </ButtonLink>

            {base.items && (
              <Sub $isType={base.isType}>
                {base.items.map(
                  (sub, j) =>
                    sub.hide != `true` && (
                      <li key={j}>
                        <Link to={sub.url} onClick={linkClick}>
                          {base.isType && (
                            <CategorySvg
                              id={sub.id}
                              className={`--${sub.name}`}
                            />
                          )}

                          <span
                            dangerouslySetInnerHTML={{ __html: sub.title }}
                          />
                        </Link>
                      </li>
                    )
                )}
              </Sub>
            )}
          </li>
        ))}

        {ENABLE_SEARCH && (
          <li className="--search">
            <ButtonLink
              to={`/${translations.search.slug}`}
              onClick={searchButtonClick}
            >
              <span
                dangerouslySetInnerHTML={{ __html: translations.search.title }}
              />

              <SvgMagnifyingGlass aria-hidden="true" className="--search" />
            </ButtonLink>
          </li>
        )}
      </Container>
    </>
  )
}

const Sub = styled.ul`
  max-width: ${em(300)};
  min-width: 0;
  margin-top: ${em(8)};
  padding: ${em(24)} ${em(14)};
  display: none;
  position: absolute;
  z-index: 1;
  top: 100%;
  right: 0;
  text-align: right;
  background-color: ${({ theme }) => theme.colors.mellanbla};
  box-shadow: 0 ${em(4)} ${em(10)} rgba(0, 0, 0, 0.2);
  border-radius: ${em(10)};
  animation: 0.2s ${({ theme }) => theme.easings.default};
  animation-name: ${({ theme }) => theme.animations.slideInY(-10)},
    ${({ theme }) => theme.animations.fadeIn};

  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }

  @media ${({ theme }) => theme.mq.mediumDown} {
    max-width: none;
    position: static;
    margin-bottom: ${em(20)};
    padding: ${em(12)} ${em(4)};
    text-align: left;
    box-shadow: 0 ${em(2)} ${em(6)} rgba(0, 0, 0, 0.2);
  }

  li {
    &:not(:last-child) {
      margin-bottom: ${em(6)};
    }
  }

  a {
    ${({ theme, $isType }) =>
      theme.fonts.set(`primary`, $isType ? `bold` : `normal`)};

    padding: ${em(4)} ${em(10)};
    display: flex;
    align-items: center;
    gap: ${em(12)};
    text-align: left;
    overflow: hidden;
  }

  span {
    font-size: ${em(17)};
  }

  svg {
    width: ${em(30)};
    height: ${em(30)};
    flex-shrink: 0;

    &.--food,
    &.--discover {
      padding: ${em(2)} 0;
    }
  }
`

const Container = styled.ul`
  display: flex;

  @media ${({ theme }) => theme.mq.mediumDown} {
    display: block;
    font-size: ${em(14)};
  }

  a,
  button {
    &:hover {
      color: ${({ theme }) => theme.colors.active};
    }
  }

  > li {
    position: relative;

    &.--active {
      > a,
      > button {
        color: ${({ theme }) => theme.colors.active};

        svg {
          transform: rotate(180deg);
        }
      }

      ${Sub} {
        display: block;
      }
    }

    &.--search {
      @media ${({ theme }) => theme.mq.mediumDown} {
        display: none;
      }
    }

    > a,
    > button {
      ${({ theme }) => theme.fonts.set(`primary`, `bold`)};

      padding: ${em(10)} ${em(16)};
      display: flex;
      align-items: baseline;
      gap: ${em(10)};
      text-transform: uppercase;

      @media ${({ theme }) => theme.mq.mediumDown} {
        padding: ${em(6)} ${em(10)};
        margin: 0 ${em(-10)};
      }

      span {
        font-size: ${em(15)};
      }

      svg {
        width: ${em(14)};
        height: ${em(14)};
        flex-shrink: 0;
      }
    }
  }
`
