const cookiesConsent = {
  textColor: ({ theme }) => theme.colors.ljusbla,
  borderColor: ({ theme }) => theme.colors.mellanbla,
  backgroundColor: ({ theme }) => theme.colors.morkbla,
  br: 0,
  buttonFont: ({ theme }) => theme.fonts.set(`primary`, `bold`),
  buttonTextColor: ({ theme }) => theme.colors.mellanbla,
  buttonBackgroundColor: ({ theme }) => theme.colors.ljusbla,
  buttonBr: 0,
}

export default cookiesConsent
