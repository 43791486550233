import React from "react"
import { graphql } from "gatsby"
import { Meta, Link } from "@outdoormap/gatsby-plugin-brand-sites"
import styled from "styled-components"
import { em } from "polished"

import Layout from "../components/layout"
import Sites from "../components/sites"
import CardsCarousel from "../components/cards-carousel"
import PostCard from "../components/post-card"
import Styled from "../components/styled"
import { Heading1, Heading3, Heading4 } from "../components/styled/heading"
import { ParagraphSmall } from "../components/styled/paragraph"
import { anchorStyles } from "../components/styled/anchor"
import { ReactComponent as DeleteSvg } from "../assets/images/icons/delete.svg"

export default function MunicipalitiesPostTemplate({
  data: { config, translations, municipality, articles, events },
}) {
  return (
    <Layout>
      <Sites
        sites={municipality.sites}
        municipality={municipality}
        afterMap={
          <Header>
            <Heading1>{municipality.name}</Heading1>

            <Cta>
              <Link
                to={`/${config.types[0].slug}`}
                title={config.types[0].title}
              >
                <DeleteSvg aria-hidden="true" />

                <span>{translations.global.clearLocationFilter}</span>
              </Link>
            </Cta>

            {municipality.tagline && <Tagline>{municipality.tagline}</Tagline>}
          </Header>
        }
      />

      <Extras>
        {!!municipality.description && (
          <About>
            <div>
              <Heading3 as="h2">
                {translations.global.about} {municipality.name}
              </Heading3>

              <Styled
                dangerouslySetInnerHTML={{ __html: municipality.description }}
              />
            </div>
          </About>
        )}

        {!!articles.nodes.length && (
          <Related>
            <Heading4 as="h2">{config.pages.articles.title}</Heading4>

            <CardsCarousel visibleSlides={3}>
              {articles.nodes.map((a) => (
                <PostCard key={a.id} post={a} headingNum={3} />
              ))}
            </CardsCarousel>
          </Related>
        )}

        {!!events.nodes.length && (
          <Related>
            <Heading4 as="h2">{config.pages.events.title}</Heading4>

            <CardsCarousel visibleSlides={3}>
              {events.nodes.map((a) => (
                <PostCard key={a.id} post={a} headingNum={3} />
              ))}
            </CardsCarousel>
          </Related>
        )}
      </Extras>
    </Layout>
  )
}

export const Head = ({ data: { municipality } }) => {
  return (
    <Meta
      data={{
        title: municipality.name,
        description: municipality.description,
        socialImage: municipality.image?.images.fallback.src,
      }}
    />
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    config: configJson {
      types {
        title
        slug
      }

      pages {
        articles {
          title
        }
        events {
          title
        }
      }
    }

    translations: translationsJson {
      global {
        clearLocationFilter
        about
      }
    }

    municipality: municipalitiesJson(id: { eq: $id }) {
      id: nk_id
      name
      tagline: _tagline
      description: _description
      image {
        ...NKimageFragment
      }
      sites {
        ...SitesCardFragment
      }
    }

    articles: allArticlesJson(filter: { municipality: { id: { eq: $id } } }) {
      nodes {
        ...ArticlesCardFragment
      }
    }

    events: allEventsJson(filter: { municipality: { id: { eq: $id } } }) {
      nodes {
        ...EventsCardFragment
      }
    }
  }
`

const Header = styled.header`
  ${({ theme }) => theme.grid.container()}

  padding-top: ${em(70)};
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: ${em(20)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-top: ${em(30)};
  }
`

const Cta = styled.aside`
  position: relative;
  top: ${em(-2)};

  a {
    display: flex;
    align-items: baseline;
    gap: ${em(6)};
  }

  svg {
    width: ${em(11)};
    height: ${em(11)};
    flex-shrink: 0;
    color: ${({ theme }) => theme.colors.active};
  }

  span {
    ${anchorStyles}

    font-size: ${em(15)};
    color: ${({ theme }) => theme.colors.foreground};
  }
`

const Tagline = styled(ParagraphSmall)`
  flex: 1 0 100%;
`

const Extras = styled.div`
  padding-top: ${em(70)};
  padding-bottom: ${em(150)};
  display: flex;
  flex-direction: column;
  gap: ${em(100)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-top: ${em(20)};
    padding-bottom: ${em(50)};
    gap: ${em(30)};
  }
`

const About = styled.section`
  ${({ theme }) => theme.grid.container()}

  > div {
    max-width: ${em(800)};
    display: flex;
    flex-direction: column;
    gap: ${em(16)};
  }
`

const Related = styled.section`
  ${({ theme }) => theme.grid.container()}

  display: flex;
  flex-direction: column;
  gap: ${em(24)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    gap: ${em(16)};
  }
`
