export default [
  {
    code: `sv`,
    title: `Svenska`,
    url: `https://www.hittafiske.se`,
  },
  {
    code: `en`,
    title: `English`,
    url: `https://en.hittafiske.se`,
  },
  {
    code: `de`,
    title: `Deutsch`,
    url: `https://de.hittafiske.se`,
  },
]
