import React from "react"
import styled, { css } from "styled-components"
import { em } from "polished"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import ImagePlaceholder from "../image-placeholder"
import CategorySvg from "../category-svg"
import { Heading3 } from "../styled/heading"
import { ParagraphXSmall } from "../styled/paragraph"

export default function SiteCard({
  site: { name, path, image, excerpt, mainCategory },
  headingNum,
  hideOutline = false,
}) {
  return (
    <Container $hideOutline={hideOutline}>
      <Link to={path} aria-label={name}>
        <Media>
          {image?.mediaType == `image` && (
            <GatsbyImage image={image} alt={name} />
          )}

          {image?.mediaType == `video` && (
            <video src={image.url} autoPlay playsInline loop muted />
          )}

          {!image && <ImagePlaceholder alt={name} />}

          <Category>
            <CategorySvg id={mainCategory.id} />

            {mainCategory.name}
          </Category>
        </Media>

        <Text>
          {name && (
            <Heading3
              as={`h${headingNum}`}
              dangerouslySetInnerHTML={{ __html: name }}
            />
          )}

          {excerpt && (
            <Description
              dangerouslySetInnerHTML={{
                __html: excerpt,
              }}
            />
          )}
        </Text>
      </Link>
    </Container>
  )
}

const Media = styled.figure`
  width: 100%;
  max-height: ${em(280)};
  aspect-ratio: 3 / 2;
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.imageBackground};

  @media ${({ theme }) => theme.mq.mediumDown} {
    max-height: ${em(180)};
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    display: block;
  }

  video {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    background-color: ${({ theme }) => theme.colors.black};
  }

  .gatsby-image-wrapper,
  video {
    a:hover & {
      opacity: 0.9;
    }
  }
`

const Category = styled.div`
  ${({ theme }) => theme.fonts.set(`secondary`, `semibold`)}

  padding: ${em(6)} ${em(12)};
  position: absolute;
  z-index: 1;
  left: ${em(20)};
  bottom: 0;
  display: flex;
  gap: ${em(10)};
  align-items: center;
  font-size: ${em(13)};
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.hjortron};

  svg {
    width: ${em(30)};
    height: ${em(30)};
    flex-shrink: 0;
  }
`

const Description = styled(ParagraphXSmall)`
  margin-top: ${em(6)};
`

const Text = styled.div`
  padding: ${em(16)} ${em(20)} ${em(20)};
  flex-grow: 1;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.midnatt};

  a:hover & {
    background-color: ${({ theme }) => theme.colors.mellanbla};
  }
`

const Container = styled.article`
  width: 100%;
  display: flex;

  a {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.white};

    ${({ $hideOutline }) =>
      $hideOutline &&
      css`
        outline: none;
      `}
  }
`
