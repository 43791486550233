import { rgba } from "polished"

const infoblocks = {
  backgroundColor: ({ theme }) => rgba(theme.colors.white, 0.6),
  openBackgroundColor: ({ theme }) => theme.colors.white,
  borderColor: ({ theme }) => theme.colors.lightBorder,
  borderRadius: 0,
}

export default infoblocks
