import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { em } from "polished"
import { ImagesCarousel } from "@outdoormap/gatsby-plugin-brand-sites"

import CardsCarousel from "../cards-carousel"
import SitesCarousel from "../sites-carousel"
import PostCard from "../post-card"
import Infobox from "../infobox"
import Section from "../section"
import Map from "../map"
import Styled from "../styled"
import { Heading1, Heading4 } from "../styled/heading"
import { ParagraphLarge } from "../styled/paragraph"

export default function Post({
  data,
  other,
  otherHeading,
  otherCta,
  otherCtaUrl,
}) {
  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          download
          relatedPlaces
        }
      }
    }
  `)

  if (!data) return null

  const anySites = !!data?.sites?.length
  const mapQuery = anySites
    ? data.sites.map((s) => `site=${s.id}`).join(`&`)
    : null

  return (
    <>
      <Article>
        {!!data?.images?.length && (
          <Images>
            <ImagesCarouselWrap>
              <ImagesCarousel data={data.images} title={data.title} />
            </ImagesCarouselWrap>

            {data.date && (
              <Date>
                <div>
                  <time dateTime={data.date}>
                    <Heading4 as="span">{data.date}</Heading4>
                  </time>
                </div>
              </Date>
            )}

            {data.__typename == `ArticlesJson` && !!data.categories.length && (
              <Category>
                <div>
                  <span>{data.categories[0].name}</span>
                </div>
              </Category>
            )}
          </Images>
        )}

        <Inner>
          <Header>
            {data.title && (
              <Title dangerouslySetInnerHTML={{ __html: data.title }} />
            )}

            {data.tagline && (
              <Tagline dangerouslySetInnerHTML={{ __html: data.tagline }} />
            )}
          </Header>

          {data.body && (
            <Content dangerouslySetInnerHTML={{ __html: data.body }} />
          )}

          <InfoboxContainer>
            <Infobox data={data} />
          </InfoboxContainer>

          {anySites && (
            <Sites>
              <Heading4
                as="h2"
                dangerouslySetInnerHTML={{
                  __html: translations.global.relatedPlaces,
                }}
              />

              <SitesMap>
                <Map
                  query={mapQuery}
                  menu="fullscreen"
                  menuActive="fullscreen"
                  filters="fullscreen"
                  scrollZoom="false"
                  styleControl="fullscreen"
                  zoomControl="fullscreen"
                  measureControl="fullscreen"
                  locationControl="fullscreen"
                  printControl="fullscreen"
                />
              </SitesMap>

              <SitesCarousel
                sites={data.sites}
                siteHeadingNum={3}
                visibleSlides={2}
                contained={false}
              />
            </Sites>
          )}
        </Inner>
      </Article>

      {!!other?.nodes?.length && (
        <Other>
          <Section
            heading={otherHeading}
            headingNum={2}
            headingStyled={Heading4}
            moreText={otherCta}
            moreLink={otherCtaUrl}
            invertColors={true}
          >
            <CardsCarousel visibleSlides={3}>
              {other.nodes.map((o) => (
                <PostCard
                  key={o.id}
                  post={o}
                  headingNum={3}
                  hideOutline={true}
                />
              ))}
            </CardsCarousel>
          </Section>
        </Other>
      )}
    </>
  )
}

const Article = styled.article``

const Images = styled.div`
  width: 100%;
  height: 60vh;
  min-height: ${em(320)};
  max-height: ${em(480)};
  position: relative;

  @media ${({ theme }) => theme.mq.mediumDown} {
    height: auto;
    min-height: auto;
    max-height: auto;
    aspect-ratio: 16 / 9;
  }
`

const ImagesCarouselWrap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
`

const Category = styled.div`
  ${({ theme }) => theme.fonts.set(`secondary`, `semibold`)}

  width: 100%;
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;

  > div {
    ${({ theme }) => theme.grid.container(`xnarrow`)}

    display: flex;

    > span {
      padding: ${em(6)} ${em(12)};
      font-size: ${em(20)};
      text-transform: uppercase;
      background-color: ${({ theme }) => theme.colors.hjortron};
    }
  }
`

const Date = styled.div`
  width: 100%;
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;

  div {
    ${({ theme }) => theme.grid.container(`xnarrow`)}

    display: flex;
  }

  time {
    padding: ${em(6)} ${em(10)};
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.himmelsbla};
  }
`

const Inner = styled.div`
  padding: ${em(60)} 0 ${em(80)};
  overflow: hidden;
  background-image: ${({ theme }) => theme.helpers.waveUrl(theme.colors.white)};
  background-size: 105% ${em(480)};
  background-position: center bottom ${em(-40)};
  background-repeat: no-repeat;

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding: ${em(30)} 0 ${em(40)};
  }
`

const Header = styled.header`
  ${({ theme }) => theme.grid.container(`xnarrow`)}

  margin-bottom: ${em(30)};
  position: relative;

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-bottom: ${em(20)};
  }

  &::before {
    content: "";
    width: ${em(270)};
    height: ${em(370)};
    margin-right: ${em(40)};
    position: absolute;
    top: 0;
    right: 100%;
    background-image: ${({ theme }) =>
      theme.helpers.scalesUrl(theme.colors.white)};
    background-position: center center;
    background-size: container;
  }
`

const Title = styled(Heading1)``

const Tagline = styled(ParagraphLarge)`
  margin-top: ${em(12)};
`

const Content = styled(Styled)`
  ${({ theme }) => theme.grid.container(`xnarrow`)}
`

const InfoboxContainer = styled.div`
  ${({ theme }) => theme.grid.container(`xnarrow`)}

  margin-top: ${em(30)};

  &:empty {
    display: none;
  }

  @media ${({ theme }) => theme.mq.smallDown} {
    margin-top: ${em(20)};
  }
`

const Sites = styled.footer`
  ${({ theme }) => theme.grid.container(`xnarrow`)}

  margin-top: ${em(50)};

  @media ${({ theme }) => theme.mq.smallDown} {
    margin-top: ${em(30)};
  }
`

const SitesMap = styled.section`
  margin-top: ${em(20)};
  margin-bottom: ${em(20)};
  height: clamp(${em(180)}, 33vh, ${em(320)});

  > * {
    overflow: hidden;
  }
`

const Other = styled.section`
  padding-top: ${em(150)};
  padding-bottom: ${em(150)};
  background-color: ${({ theme }) => theme.colors.midnatt};
  background-image: ${({ theme }) =>
    theme.helpers.scalesPatternUrl(theme.colors.morkbla)};
  background-size: 70%;
  background-position: center center;
  background-repeat: repeat;

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${em(20)};
    padding-top: ${em(50)};
    padding-bottom: ${em(50)};
    background-size: 120%;
  }
`
