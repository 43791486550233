import { rem } from "polished"

const br = {
  xsmall: rem(4),
  small: rem(10),
  normal: rem(16),
  large: rem(22),
}

export default br
