import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { em } from "polished"
import {
  GlobalNotice,
  CookiesConsent,
} from "@outdoormap/gatsby-plugin-brand-sites"

import Header from "../header"
import Footer from "../footer"
import { Anchor } from "../styled/anchor"
import { Paragraph, ParagraphSmall } from "../styled/paragraph"

export default function Layout({ children }) {
  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          skipToContent
        }
      }
    }
  `)

  return (
    <>
      <SkipToContent href="#content">
        {translations.global.skipToContent}
      </SkipToContent>

      <header>
        <GlobalNotice Anchor={Anchor} Paragraph={Paragraph} />

        <Header tagName="div" />
      </header>

      <main id="content">{children}</main>

      <Footer />

      <CookiesConsent Text={ParagraphSmall} />
    </>
  )
}

const SkipToContent = styled.a`
  ${({ theme }) => theme.fonts.set(`primary`, `bold`)};

  padding: ${em(14)} ${em(22)};
  position: fixed;
  z-index: ${({ theme }) => theme.zindex.skipToMain};
  top: ${em(10)};
  left: ${em(10)};
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.hjortron};
  outline-offset: ${em(4)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: ${em(13)};
  }

  &:not(:focus) {
    pointer-events: none;
    opacity: 0;
  }

  html.--mobile-header & {
    display: none;
  }
`
