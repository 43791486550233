import React, { useMemo, useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { em } from "polished"
import { GatsbyImage } from "gatsby-plugin-image"
import { Meta } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import PostsHighlights from "../components/posts-highlights"
import CategoriesFilter from "../components/categories-filter"
import { Heading1 } from "../components/styled/heading"
import { Paragraph } from "../components/styled/paragraph"

const collectCategories = (articles) => {
  const categories = {}
  articles.forEach((s) => s.categories.forEach((c) => (categories[c.id] = c)))
  return Object.values(categories)
}

const filterArticles = (articles, selectedCategories) => {
  if (!selectedCategories.length) return articles

  return articles.filter((s) =>
    s.categories.find((c) => selectedCategories.includes(c.id))
  )
}

export default function ArticlesTemplate({
  data: {
    translations,
    config,
    articles: { nodes: articles },
    image,
  },
}) {
  const [selectedCategories, setSelectedCategories] = useState([])
  const categories = useMemo(() => collectCategories(articles), [articles])
  const articlesFiltered = useMemo(
    () => filterArticles(articles, selectedCategories),
    [articles, selectedCategories]
  )

  return (
    <Layout>
      {image && (
        <Image>
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt={config.pages.articles.title}
          />
        </Image>
      )}

      <Content>
        <div>
          <Title>
            <Heading1
              as="span"
              dangerouslySetInnerHTML={{
                __html: config.pages.articles.title,
              }}
            />
          </Title>

          {articles.length ? (
            <Articles>
              {categories.length && (
                <CategoriesFilter
                  categories={categories}
                  selectedCategories={selectedCategories}
                  onSelectCategories={setSelectedCategories}
                  showIcons={false}
                  contained={false}
                  invertColors={true}
                />
              )}

              <PostsHighlights
                posts={articlesFiltered}
                postHeadingNum={2}
                chunksPerPage={3}
                shouldResetPage={selectedCategories}
              />
            </Articles>
          ) : (
            <Empty>
              <Paragraph
                dangerouslySetInnerHTML={{
                  __html: translations.global.noContent,
                }}
              />
            </Empty>
          )}
        </div>
      </Content>
    </Layout>
  )
}

export const Head = ({ data: { config, image } }) => {
  return (
    <Meta
      data={{
        title: config.pages.articles.title,
        socialImage: image?.childImageSharp.gatsbyImageData.images.fallback.src,
      }}
    />
  )
}

export const pageQuery = graphql`
  query {
    translations: translationsJson {
      global {
        noContent
      }
    }

    config: configJson {
      pages {
        articles {
          title
        }
      }
    }

    articles: allArticlesJson(sort: { publish_at: DESC }) {
      nodes {
        ...ArticlesCardFragment
      }
    }

    image: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "content/fishing.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

const Image = styled.div`
  height: 33vh;
  min-height: ${em(240)};
  max-height: ${em(480)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    min-height: ${em(180)};
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

const Content = styled.div`
  padding-top: ${em(80)};
  padding-bottom: ${em(100)};
  background-color: ${({ theme }) => theme.colors.midnatt};
  background-image: ${({ theme }) =>
    theme.helpers.scalesPatternUrl(theme.colors.morkbla)};
  background-size: 70%;
  background-position: center center;
  background-repeat: repeat;

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-top: ${em(30)};
    padding-bottom: ${em(40)};
    background-size: 120%;
  }

  > div {
    ${({ theme }) => theme.grid.container()}
  }
`

const Title = styled.h1`
  margin-bottom: ${em(30)};
  color: ${({ theme }) => theme.colors.white};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-bottom: ${em(20)};
  }
`

const Articles = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${em(40)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    gap: ${em(24)};
  }
`

const Empty = styled.div`
  max-width: 60ch;
  color: ${({ theme }) => theme.colors.white};
`
