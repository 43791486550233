import React, { useState, useEffect } from "react"
import { RemoveScroll } from "react-remove-scroll"
import FocusTrap from "focus-trap-react"
import { useMediaQuery } from "@react-hook/media-query"
import { useStaticQuery, graphql } from "gatsby"
import styled, { css } from "styled-components"
import { em } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import Nav from "./nav"
import SearchModal from "../search-modal"
import { ReactComponent as DeleteSvg } from "../../assets/images/icons/delete.svg"
import { ReactComponent as MenuSvg } from "../../assets/images/icons/menu.svg"
import { ReactComponent as SvgMagnifyingGlass } from "../../assets/images/icons/magnifying-glass.svg"
import logoUrl from "../../assets/images/logo.svg"
import mq from "../../theme/mq"

const ENABLE_SEARCH = !!process.env.GATSBY_TYPESENSE_KEY

export default function Header({ tagName = `header` }) {
  const { site, translations } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          name
        }
      }

      translations: translationsJson {
        global {
          menuHide
          menuShow
        }

        search {
          title
          slug
        }
      }
    }
  `)

  const [mobileActive, setMobileActive] = useState(false)
  const [searchModalOpen, setSearchModalOpen] = useState(false)
  const mqMobile = useMediaQuery(mq.mediumDown)

  useEffect(() => {
    if (!mqMobile) setMobileActive(false)
  }, [mqMobile])

  useEffect(() => {
    document.body.classList.toggle(`--mobile-header`, mobileActive)
  }, [mobileActive])

  const navToggleClick = () => setMobileActive(!mobileActive)
  const searchToggleClick = () => setSearchModalOpen(true)
  const navSearchOpen = () => setSearchModalOpen(true)

  const searchModalRequestClose = () => {
    setSearchModalOpen(false)
  }

  return (
    <>
      <RemoveScroll enabled={mobileActive}>
        <FocusTrap active={mobileActive && !searchModalOpen}>
          <Container as={tagName} $active={mobileActive}>
            <Inner>
              <Bar $active={mobileActive}>
                <Logo>
                  <Link to="/" title={site.siteMetadata.name}>
                    <img src={logoUrl} alt={site.siteMetadata.name} />
                  </Link>
                </Logo>

                <Toggle
                  title={translations.search.title}
                  onClick={searchToggleClick}
                  // to={`/${translations.search.slug}`}
                >
                  <SvgMagnifyingGlass aria-label={translations.search.title} />
                </Toggle>

                <Toggle
                  title={
                    mobileActive
                      ? translations.global.menuHide
                      : translations.global.menuShow
                  }
                  onClick={navToggleClick}
                  aria-expanded={mobileActive}
                  aria-controls="header-nav"
                >
                  {mobileActive ? (
                    <DeleteSvg aria-label={translations.global.menuHide} />
                  ) : (
                    <MenuSvg aria-label={translations.global.menuShow} />
                  )}
                </Toggle>
              </Bar>

              <NavWrap id="header-nav" aria-label="Main navigation">
                <Nav onSearchOpen={navSearchOpen} />
              </NavWrap>
            </Inner>
          </Container>
        </FocusTrap>
      </RemoveScroll>

      {ENABLE_SEARCH && (
        <SearchModal
          isOpen={searchModalOpen}
          onRequestClose={searchModalRequestClose}
        />
      )}
    </>
  )
}

const Bar = styled.div`
  flex-shrink: 0;
  order: 2;
  display: flex;
  align-items: center;
  gap: ${em(20)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    ${({ theme }) => theme.grid.container()}

    padding-top: ${em(12)};
    padding-bottom: ${em(12)};

    ${({ $active }) =>
      $active &&
      css`
        position: sticky;
        z-index: 10;
        top: 0;
        border-bottom: 1px solid ${({ theme }) => theme.colors.mellanbla};
      `}
  }

  > *:nth-child(2) {
    margin-left: auto;
  }
`

const Logo = styled.div`
  a {
    display: block;
    outline-offset: ${em(10)};

    &:hover {
      opacity: 0.8;
    }
  }

  img {
    width: ${em(136)};
    height: ${em(60)};
    display: block;

    @media ${({ theme }) => theme.mq.mediumDown} {
      width: ${em(100)};
      height: ${em(46)};
    }

    @media ${({ theme }) => theme.mq.xsmallDown} {
      width: ${em(80)};
      height: ${em(40)};
    }
  }
`

const Toggle = styled.button.attrs({ type: `button` })`
  width: ${em(40)};
  height: ${em(40)};
  padding: ${em(10)};
  margin-right: ${em(-10)};
  flex-shrink: 0;

  @media ${({ theme }) => theme.mq.mediumUp} {
    display: none;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.active};
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`

const NavWrap = styled.nav`
  flex: 1;
  order: 3;
  display: flex;
  justify-content: flex-end;

  @media ${({ theme }) => theme.mq.mediumDown} {
    ${({ theme }) => theme.grid.container()}

    display: none;
    padding-top: ${em(12)};
    padding-bottom: ${em(100)};
  }
`

const Inner = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${({ theme }) => theme.mq.mediumUp} {
    ${({ theme }) => theme.grid.container()}
  }

  @media ${({ theme }) => theme.mq.mediumDown} {
    display: block;
  }
`

const Container = styled.header`
  width: 100%;
  padding-top: ${em(12)};
  padding-bottom: ${em(12)};
  position: relative;
  z-index: ${({ theme }) => theme.zindex.header};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.midnatt};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-top: 0;
    padding-bottom: 0;
  }

  ${({ $active }) =>
    $active &&
    css`
      @media ${({ theme }) => theme.mq.mediumDown} {
        ${({ theme }) => theme.helpers.hideScrollbar()}

        width: 100%;
        height: 100%;
        display: block;
        position: fixed;
        z-index: ${({ theme }) => theme.zindex.headerActive};
        top: 0;
        left: 0;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        will-change: scroll-position;
        background-color: ${({ theme }) => theme.colors.midnatt};

        ${NavWrap} {
          display: block;
        }
      }
    `}
`
