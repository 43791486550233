import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { em } from "polished"

import Button from "../button"
import SiteCard from "../site-card"

const PER_PAGE = 9

export default function SitesGrid({ sites, siteHeadingNum }) {
  const [page, setPage] = useState(1)

  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          showMore
        }
      }
    }
  `)

  const hasMore = sites.length > page * PER_PAGE

  const moreClick = () => {
    if (hasMore) setPage((p) => p + 1)
  }

  if (!sites.length) return null

  return (
    <Container>
      <Grid>
        {sites.slice(0, page * PER_PAGE).map((s) => (
          <SiteCard key={s.id} site={s} headingNum={siteHeadingNum} />
        ))}
      </Grid>

      {hasMore && (
        <More>
          <Button
            type="button"
            display="button"
            size="large"
            foreground="white"
            background="hallon"
            onClick={moreClick}
          >
            {translations.global.showMore}
          </Button>
        </More>
      )}
    </Container>
  )
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${em(20)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${({ theme }) => theme.mq.xsmallDown} {
    grid-template-columns: repeat(1, 1fr);
  }
`

const More = styled.div`
  margin-top: ${em(40)};
  display: flex;
  justify-content: center;

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${em(24)};
  }
`

const Container = styled.div`
  ${({ theme }) => theme.grid.container()}
`
