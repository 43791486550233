import React from "react"
import styled, { css } from "styled-components"
import { em } from "polished"

import Button from "../button"
import { Heading2 } from "../styled/heading"
import { ReactComponent as SvgArrowRight } from "../../assets/images/icons/arrow-right.svg"

export default function Section({
  children,
  heading,
  headingNum,
  headingStyled,
  moreText,
  moreLink,
  container = `normal`,
  invertColors = false,
}) {
  const HeadingStyled = headingStyled || Heading2

  return (
    <Container
      as={heading ? `section` : `div`}
      $container={container}
      $invertColors={invertColors}
    >
      {heading && (
        <Heading as={`h${headingNum}`}>
          <HeadingStyled
            as="span"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        </Heading>
      )}

      <div>{children}</div>

      {moreText && moreLink && (
        <More>
          <Button
            to={moreLink}
            icon={<SvgArrowRight />}
            iconpos="right"
            display="anchor"
            foreground="hallon"
          >
            {moreText}
          </Button>
        </More>
      )}
    </Container>
  )
}

const Heading = styled.h2`
  margin-bottom: ${em(30)};

  @media ${({ theme }) => theme.mq.smallDown} {
    margin-bottom: ${em(20)};
  }
`

const More = styled.div`
  margin-top: ${em(30)};
  text-transform: uppercase;

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${em(20)};
  }
`

const Container = styled.section`
  ${({ theme, $container }) => $container && theme.grid.container($container)}

  ${({ $invertColors }) =>
    $invertColors &&
    css`
      ${Heading} {
        color: ${({ theme }) => theme.colors.white};
      }
    `}
`
