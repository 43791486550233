import React, { useRef, useState, useEffect, useId, useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { em } from "polished"
import { sortBy, debounce } from "lodash"

import { ReactComponent as SvgChevronLeft } from "../../assets/images/icons/chevron-left.svg"
import { ReactComponent as SvgChevronRight } from "../../assets/images/icons/chevron-right.svg"

export default function CategoriesFilter({
  categories: categoriesProp,
  selectedCategories,
  onSelectCategories,
  noneWhenAll = true, // if true, when selecting all categories the component responds with `[]` via `onSelectCategories` rather than `categories`
  showIcons = true,
  contained = true,
  invertColors = false,
}) {
  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          all
          next
          previous
          categories
        }
      }
    }
  `)

  const [navPrevEnabled, setNavPrevEnabled] = useState(false)
  const [navNextEnabled, setNavNextEnabled] = useState(true)
  const categoriesScrollDirectionRef = useRef(`next`)
  const categoriesRef = useRef()
  const categoriesHtmlId = useId()

  const categories = useMemo(
    () => sortBy(categoriesProp, [`name`]),
    [categoriesProp]
  )

  const isAll =
    !selectedCategories.length || selectedCategories.length >= categories.length

  const categoryClick = (e) => {
    const { id } = e.currentTarget.dataset
    let selected = []

    if (isAll) selected.push(id)
    else {
      selected = [...selectedCategories]
      if (selected.includes(id)) selected = selected.filter((s) => s != id)
      else selected.push(id)
    }

    const url = new URL(window.location.href)

    if (selected.length >= categories.length) {
      // all
      url.searchParams.delete(`categories`)
      if (noneWhenAll) onSelectCategories([])
      else onSelectCategories(selected)
    } else {
      // some
      url.searchParams.set(`categories`, selected.join(`-`))
      onSelectCategories(selected)
    }

    window.history.replaceState(null, null, url.toString())
  }

  const allClick = () => {
    if (noneWhenAll) onSelectCategories([])
    else onSelectCategories(categories.map((c) => c.id))

    const url = new URL(window.location.href)
    url.searchParams.delete(`categories`)
    window.history.replaceState(null, null, url.toString())
  }

  const navClick = (e) => {
    const { direction } = e.currentTarget.dataset

    if (categoriesScrollDirectionRef.current != direction) {
      categoriesScrollDirectionRef.current = direction
      const { scrollLeft } = categoriesRef.current
      categoriesRef.current.dataset.direction = direction
      categoriesRef.current.scrollLeft = scrollLeft
    }

    categoriesRef.current.scrollBy({
      left: direction == `prev` ? -100 : 100,
      top: 0,
      behavior: `smooth`,
    })
  }

  const categoriesScroll = debounce(() => {
    const { scrollLeft, scrollWidth, offsetWidth } = categoriesRef.current

    setNavPrevEnabled(scrollLeft > 5)
    setNavNextEnabled(scrollLeft + 5 < scrollWidth - offsetWidth)
  }, 200)

  const selectCategoriesByUrl = () => {
    const url = new URL(window.location.href)
    let urlCategories = url.searchParams.get(`categories`)
    if (urlCategories) {
      urlCategories = urlCategories
        .split(`-`)
        .filter((c) => categories.find((cc) => cc.id == c))

      if (urlCategories.length) onSelectCategories(urlCategories)
    }
  }

  useEffect(() => {
    categoriesScroll()

    if (typeof window !== `undefined`) {
      selectCategoriesByUrl()
      window.addEventListener(`gatsbyRouteChange`, selectCategoriesByUrl)
      window.addEventListener(`resize`, categoriesScroll)
    }

    return () => {
      if (typeof window !== `undefined`) {
        window.removeEventListener(`gatsbyRouteChange`, selectCategoriesByUrl)
        window.removeEventListener(`resize`, categoriesScroll)
      }
    }
  }, [])

  if (categories.length <= 1) return null

  return (
    <Container $contained={contained}>
      {navPrevEnabled && (
        <Nav
          onClick={navClick}
          data-direction="prev"
          role="navigation"
          aria-controls={categoriesHtmlId}
          title={translations.global.previous}
          $invertColors={invertColors}
        >
          <SvgChevronLeft aria-label={translations.global.previous} />
        </Nav>
      )}

      <Categories
        ref={categoriesRef}
        onScroll={categoriesScroll}
        data-direction="next"
        role="listbox"
        aria-label={translations.global.categories}
        id={categoriesHtmlId}
      >
        <div>
          <Category
            onClick={allClick}
            dangerouslySetInnerHTML={{ __html: translations.global.all }}
            role="option"
            aria-selected={isAll}
          />

          {categories.map((item) => (
            <Category
              key={item.id}
              data-id={item.id}
              onClick={categoryClick}
              role="option"
              aria-selected={!isAll && selectedCategories.includes(item.id)}
            >
              {showIcons && (
                <img
                  src={`https://assets.naturkartan.se/assets/categories/${item.id}/outline.svg`}
                  alt=""
                  role="presentation"
                />
              )}

              {item.name}
            </Category>
          ))}
        </div>
      </Categories>

      {navNextEnabled && (
        <Nav
          onClick={navClick}
          data-direction="next"
          role="navigation"
          aria-controls={categoriesHtmlId}
          title={translations.global.next}
          $invertColors={invertColors}
        >
          <SvgChevronRight aria-label={translations.global.next} />
        </Nav>
      )}
    </Container>
  )
}

const Container = styled.div`
  ${({ theme, $contained }) => $contained && theme.grid.container()}

  position: relative;

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: ${em(14)};
  }
`

const Nav = styled.button.attrs({ type: `button` })`
  width: ${em(30)};
  height: ${em(30)};
  position: absolute;
  z-index: 2;
  top: 50%;
  translate: 0 -50%;
  color: ${({ theme, $invertColors }) =>
    theme.colors[$invertColors ? `morkbla` : `white`]};
  background-color: ${({ theme, $invertColors }) =>
    theme.colors[$invertColors ? `ljusbla` : `morkbla`]};
  border: 1px solid
    ${({ $invertColors }) => ($invertColors ? `currentcolor` : `transparent`)};
  border-radius: 50%;
  box-shadow: 0 ${em(1)} ${em(3)} rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: ${({ theme, $invertColors }) =>
      theme.colors[$invertColors ? `white` : `mellanbla`]};
  }

  &[data-direction="prev"] {
    left: 0;
    translate: -50% -50%;
  }

  &[data-direction="next"] {
    right: 0;
    translate: 50% -50%;
  }

  &::before {
    content: "";
    position: absolute;
    inset: ${em(-8)} ${em(-4)};
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
    padding: ${em(9)};
  }
`

const Category = styled.button.attrs({ type: `button` })`
  ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

  padding: ${em(10)} ${em(22)};
  display: flex;
  align-items: center;
  white-space: nowrap;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid transparent;
  outline-offset: ${em(4)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding: ${em(6)} ${em(16)};
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.hallon};
  }

  &[aria-selected="true"] {
    border-color: transparent;
    background-color: ${({ theme }) => theme.colors.hallon};
    color: ${({ theme }) => theme.colors.white};

    &:hover {
      opacity: 0.9;
    }

    img {
      filter: grayscale(1) brightness(0) invert(1);
    }
  }

  img,
  svg {
    width: ${em(20)};
    height: ${em(20)};
    margin-right: ${em(10)};
  }

  img {
    filter: grayscale(1) brightness(0);
  }

  svg {
    height: ${em(14)};
  }
`

const Categories = styled.div`
  ${({ theme }) => theme.helpers.hideScrollbar()}
  /* ${({ theme }) => theme.helpers.overflowMask(`right`)} */

  padding-top: ${em(4)};
  padding-bottom: ${em(4)};
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  position: relative;
  z-index: 1;

  div {
    display: inline-flex;
    gap: ${em(6)};
  }

  &[data-direction="prev"] {
    ${Category} {
      scroll-snap-align: start;
    }
  }

  &[data-direction="next"] {
    ${Category} {
      scroll-snap-align: end;
    }
  }
`
