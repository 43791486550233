import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { em } from "polished"
import { useCookies } from "react-cookie"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import About from "./about"
import Menus from "./menus"
import GoodToKnow from "./good-to-know"
import { Anchor } from "../styled/anchor"
import { ParagraphSmall } from "../styled/paragraph"
import euFundedLogoUrl from "../../assets/images/eu-funded-logo.svg"
import naturkartanLogoUrl from "../../assets/images/naturkartan-logo-white.svg"

const LOGOS = [
  {
    title: `Europeiska jordbruksfonden för landsbygdsutveckling. Europa investerar i landsbygdsomräden`,
    image: euFundedLogoUrl,
  },
]

const COOKIES_CONSENT_NAME = `cookies-consent`

export default function Footer() {
  const [cookies, , removeCookie] = useCookies([COOKIES_CONSENT_NAME])
  const cookiesConsented = !!cookies[COOKIES_CONSENT_NAME]

  const resetCookiesClick = () => {
    removeCookie(COOKIES_CONSENT_NAME)
    window.scrollTo({ top: 0, behavior: `instant` })
    window.location.reload()
  }

  const { site, translations, privacyPolicyPage } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          name
          lang
        }
      }

      translations: translationsJson {
        global {
          resetCookies
        }
      }

      privacyPolicyPage: pagesJson(nk_id: { eq: "privacypolicy" }) {
        title
        path
      }
    }
  `)

  return (
    <Container>
      <GoodToKnow />

      <Main>
        <div>
          <Sections>
            <div>
              <AboutWrap>
                <About />
              </AboutWrap>
            </div>

            <div>
              <div>
                <Menus />

                <Copyright>
                  © {site.siteMetadata.name} & OutdoorMap.
                  {` `}
                  {privacyPolicyPage && (
                    <>
                      <Anchor as={Link} to={privacyPolicyPage.path}>
                        {privacyPolicyPage.title}
                      </Anchor>
                      .
                    </>
                  )}
                  {` `}
                  {cookiesConsented && (
                    <>
                      <Anchor
                        as="button"
                        type="button"
                        onClick={resetCookiesClick}
                      >
                        {translations.global.resetCookies}
                      </Anchor>
                      .
                    </>
                  )}
                </Copyright>

                {!!LOGOS.length && (
                  <Logos>
                    {LOGOS.map((logo, i) => (
                      <li key={i}>
                        <img
                          src={logo.image}
                          alt={logo.title}
                          title={logo.title}
                          loading="lazy"
                        />
                      </li>
                    ))}
                  </Logos>
                )}
              </div>
            </div>
          </Sections>

          <Naturkartan>
            <Link
              to={`https://www.naturkartan.se/${site.siteMetadata.lang}`}
              target="_blank"
              title="Naturkartan"
            >
              <img src={naturkartanLogoUrl} alt="Naturkartan" />
            </Link>
          </Naturkartan>
        </div>
      </Main>
    </Container>
  )
}

const Container = styled.footer``

const Main = styled.div`
  padding: ${em(150)} 0;
  position: relative;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.midnatt};

  @media ${({ theme }) => theme.mq.xxlargeDown} {
    padding: ${em(50)} 0;
  }

  &::before {
    content: "";
    width: 100%;
    aspect-ratio: 2 / 1;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    transform: translateY(50%) translateX(-20%);
    background-image: ${({ theme }) =>
      theme.helpers.fish2Url(theme.colors.morkbla)};
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;

    @media ${({ theme }) => theme.mq.mediumDown} {
      transform: translateY(35%) translateX(-10%);
    }
  }

  > div {
    ${({ theme }) => theme.grid.container()}

    position: relative;
    z-index: 1;
  }
`

const Sections = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  row-gap: ${em(30)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    grid-template-columns: 1fr;
  }

  @media ${({ theme }) => theme.mq.smallDown} {
    background-size: ${em(24)} ${em(30)};
  }
`

const AboutWrap = styled.div`
  width: 100%;
  max-width: ${em(560)};

  @media ${({ theme }) => theme.mq.mediumUp} {
    padding-right: clamp(${em(20)}, 20%, ${em(80)});
  }
`

const Logos = styled.ul`
  margin-top: ${em(30)};
  display: flex;
  flex-wrap: wrap;
  gap: ${em(16)};

  li {
    width: ${em(120)};
    padding: ${em(6)};
    flex-shrink: 0;
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
  }

  img {
    width: 100%;
    display: block;
  }
`

const Copyright = styled(ParagraphSmall)`
  margin-top: ${em(22)};
  opacity: 0.8;
  font-size: ${em(14)};

  a,
  button {
    color: inherit;
  }
`

const Naturkartan = styled.div`
  margin-top: ${em(60)};
  display: flex;
  justify-content: center;

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${em(30)};
  }

  a {
    &:hover {
      opacity: 0.8;
    }
  }

  img {
    width: 100%;
    max-width: ${em(140)};
  }
`
