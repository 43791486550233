import React, { useState } from "react"
import styled from "styled-components"
import { useWindowWidth } from "@react-hook/window-size/throttled"
import { Carousel } from "@outdoormap/gatsby-plugin-brand-sites"
import { em, rem } from "polished"

import { breakpoints } from "../../theme/mq"

export default function CardsCarousel({ visibleSlides, children, ...props }) {
  const [theVisibileSlides, setTheVisibleSlides] = useState(visibleSlides)
  const winWidth = useWindowWidth({ initialWidth: breakpoints.xxlarge })

  const setVisibleSlides = (count) => {
    if (typeof window === `undefined`) return false
    window.setTimeout(() => setTheVisibleSlides(count), 50)
    return true
  }

  if (winWidth < breakpoints.xsmall) {
    if (visibleSlides >= 4 && theVisibileSlides != 1) setVisibleSlides(1)
    if (visibleSlides == 3 && theVisibileSlides != 1) setVisibleSlides(1)
    if (visibleSlides == 2 && theVisibileSlides != 1) setVisibleSlides(1)
  } else if (winWidth < breakpoints.medium) {
    if (visibleSlides >= 4 && theVisibileSlides != 2) setVisibleSlides(2)
    if (visibleSlides == 3 && theVisibileSlides != 1) setVisibleSlides(1)
  } else if (winWidth < breakpoints.xlarge) {
    if (visibleSlides >= 4 && theVisibileSlides != 3) setVisibleSlides(3)
    if (visibleSlides == 3 && theVisibileSlides != 2) setVisibleSlides(2)
  } else if (winWidth >= breakpoints.xlarge) {
    if (theVisibileSlides != visibleSlides) setVisibleSlides(visibleSlides)
  }

  if (!children?.length) return null

  return (
    <CarouselStyled
      slides={children}
      visibleSlides={theVisibileSlides}
      {...props}
    />
  )
}

const CarouselStyled = styled(Carousel)`
  .carousel__back-button,
  .carousel__next-button {
    top: 33%;
  }

  .carousel__slider {
    padding-bottom: ${rem(8)};
    outline-offset: ${em(10)};
  }
`
