import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { em } from "polished"
import { GatsbyImage } from "gatsby-plugin-image"
import { sortBy } from "lodash"

import { ReactComponent as FishSvg } from "../../assets/images/icons/fish.svg"

export default function Species({ categories }) {
  const { images } = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: { dir: { regex: "/src/assets/images/content/species$/i" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(width: 100)
          }
        }
      }
    }
  `)

  const species = sortBy(
    categories.map((c) => ({
      id: c.id,
      name: c.name,
      image: images.nodes.find((i) => i.name == c.slug),
    })),
    [`name`]
  )

  return (
    <Container>
      {species.map((specie) => (
        <Item key={specie.id}>
          <figure>
            {specie.image?.childImageSharp ? (
              <GatsbyImage
                image={specie.image.childImageSharp.gatsbyImageData}
                alt={specie.name}
              />
            ) : (
              <FishSvg aria-hidden="true" />
            )}

            <figcaption>
              <strong>{specie.name}</strong>
            </figcaption>
          </figure>
        </Item>
      ))}
    </Container>
  )
}

const Container = styled.ul`
  padding: ${em(20)} ${em(10)};
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: ${em(10)};
  row-gap: ${em(16)};
  background-color: ${({ theme }) => theme.colors.white};
  text-align: center;

  @media ${({ theme }) => theme.mq.smallDown} {
    grid-template-columns: repeat(4, 1fr);
  }

  @media ${({ theme }) => theme.mq.xsmallDown} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const Item = styled.li`
  figure {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }

  .gatsby-image-wrapper,
  svg {
    width: 100%;
    max-width: ${em(70)};
    margin-top: ${em(-14)};
  }

  svg {
    opacity: 0.4;
    display: block;
    padding: ${em(4)};
  }

  figcaption {
    margin-top: ${em(-14)};
    position: relative;
    z-index: 1;
    font-size: ${em(14)};
  }
`
