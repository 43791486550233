const globalNotice = {
  color: ({ theme }) => theme.colors.white,
  backgroundColor: {
    info: ({ theme }) => theme.colors.himmelsbla,
    warning: ({ theme }) => theme.colors.hjortron,
    critical: ({ theme }) => theme.colors.hallon,
  },
}

export default globalNotice
