import { ReactComponent as SvgBoatlaunch } from "../../assets/images/icons/boatlaunch.svg"
import { ReactComponent as SvgAccomodation } from "../../assets/images/icons/accomodation.svg"
import { ReactComponent as SvgFishing } from "../../assets/images/icons/fishing.svg"
import { ReactComponent as SvgFishingarea } from "../../assets/images/icons/fishingarea.svg"
import { ReactComponent as SvgShop } from "../../assets/images/icons/shop.svg"
import { ReactComponent as SvgClubhouse } from "../../assets/images/icons/clubhouse.svg"

const icons = {
  118: SvgBoatlaunch,
  55: SvgAccomodation,
  26: SvgFishing,
  229: SvgFishingarea,
  73: SvgShop,
  74: SvgClubhouse,
}

export default function CategorySvg({ id, ...props }) {
  if (!id || !icons[id]) return null

  return icons[id](props)
}
