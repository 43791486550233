import React, { useRef, useState, useEffect, useMemo, useId } from "react"
import { useClickAway, useKey } from "react-use"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import { em } from "polished"
import { sortBy } from "lodash"
import replaceSpecialCharacters from "replace-special-characters"

import { ReactComponent as DeleteSvg } from "../../assets/images/icons/delete.svg"
import { ReactComponent as FishSvg } from "../../assets/images/icons/fish.svg"
import { ReactComponent as CheckSvg } from "../../assets/images/icons/check.svg"
import { ReactComponent as ChevronDownSvg } from "../../assets/images/icons/chevron-down.svg"

export default function SpeciesFilter({
  species,
  selectedSpecies,
  onSelectSpecies,
}) {
  const { translations, siteTranslations, images } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          species
          search
        }
      }

      siteTranslations: siteTranslationsJson {
        speciesFilter {
          label
          clear
          noMatch
          noForCategory
        }
      }

      images: allFile(
        filter: { dir: { regex: "/src/assets/images/content/species$/i" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(width: 100)
          }
        }
      }
    }
  `)

  const [optionsActive, setOptionsActive] = useState(false)
  const [searchQuery, setSearchQuery] = useState(``)

  const speciesModified = useMemo(() => {
    let modified = species.map((s) => ({
      ...s,
      image: images.nodes.find((i) => i.name == s.slug),
    }))

    if (searchQuery)
      modified = modified.filter((s) =>
        replaceSpecialCharacters(s.name.toLowerCase()).includes(
          replaceSpecialCharacters(searchQuery.toLowerCase())
        )
      )

    return sortBy(modified, [`name`])
  }, [species, searchQuery])

  useEffect(() => {
    if (typeof window === `undefined`) return

    const url = new URL(window.location.href)
    let urlSpecies = url.searchParams.get(`species`)

    if (urlSpecies) {
      urlSpecies = urlSpecies
        .split(`-`)
        .filter((c) => species.find((cc) => cc.id == c))

      if (urlSpecies.length) onSelectSpecies(urlSpecies)
    }
  }, [])

  useEffect(() => {
    if (typeof window === `undefined`) return

    const url = new URL(window.location.href)

    if (!selectedSpecies.length || selectedSpecies.length >= species.length) {
      // all
      url.searchParams.delete(`species`)
    } else {
      // some
      url.searchParams.set(`species`, selectedSpecies.join(`-`))
    }

    window.history.replaceState(null, null, url.toString())
  }, [selectedSpecies])

  useEffect(() => setSearchQuery(``), [optionsActive])

  const containerRef = useRef()
  const inputRef = useRef()

  useClickAway(containerRef, () => setOptionsActive(false))

  useKey(`Escape`, () => {
    if (
      containerRef.current &&
      containerRef.current.contains(document.activeElement) &&
      document.activeElement !== inputRef.current
    )
      setOptionsActive(false)
  })

  const optionsHtmlId = useId()

  const toggleClick = () => setOptionsActive((v) => !v)

  const clearClick = () => {
    onSelectSpecies([])
    setOptionsActive(false)
  }

  const specieClick = (e) => {
    const id = String(e.currentTarget.dataset.id)
    if (selectedSpecies.includes(id))
      onSelectSpecies(selectedSpecies.filter((s) => s != id))
    else onSelectSpecies([...selectedSpecies, id])
  }

  const searchChange = (e) => {
    setSearchQuery(e.currentTarget.value)
  }

  return (
    <Container ref={containerRef}>
      <Main $optionsActive={optionsActive}>
        <Toggle
          onClick={toggleClick}
          $optionsActive={optionsActive}
          aria-expanded={optionsActive}
          aria-controls={optionsHtmlId}
        >
          {selectedSpecies.length ? (
            <>
              {translations.global.species} ({selectedSpecies.length})
            </>
          ) : (
            siteTranslations.speciesFilter.label
          )}
        </Toggle>

        {!!selectedSpecies.length && (
          <Clear
            onClick={clearClick}
            title={siteTranslations.speciesFilter.clear}
            aria-label={siteTranslations.speciesFilter.clear}
          >
            <DeleteSvg aria-hidden="true" />
          </Clear>
        )}

        <ChevronDownSvg aria-hidden="true" />
      </Main>

      {optionsActive && (
        <Options
          id={optionsHtmlId}
          role="search"
          aria-label={translations.global.species}
        >
          {species.length ? (
            <>
              <input
                type="search"
                role="searchbox"
                placeholder={translations.global.search}
                value={searchQuery}
                onChange={searchChange}
                ref={inputRef}
              />

              {!!speciesModified.length && (
                <ul
                  role="listbox"
                  aria-label={translations.global.species}
                  aria-live="polite"
                >
                  {speciesModified.map((specie) => (
                    <li key={specie.id}>
                      <button
                        type="button"
                        role="option"
                        aria-selected={selectedSpecies.includes(specie.id)}
                        data-id={specie.id}
                        onClick={specieClick}
                      >
                        {specie.image?.childImageSharp ? (
                          <GatsbyImage
                            image={specie.image.childImageSharp.gatsbyImageData}
                            alt={specie.name}
                            role="presentation"
                          />
                        ) : (
                          <FishSvg aria-hidden="true" />
                        )}

                        <span>{specie.name}</span>

                        {selectedSpecies.includes(String(specie.id)) && (
                          <CheckSvg aria-hidden="true" />
                        )}
                      </button>
                    </li>
                  ))}
                </ul>
              )}

              {!speciesModified.length && (
                <p role="status" aria-live="polite">
                  {siteTranslations.speciesFilter.noMatch}
                </p>
              )}
            </>
          ) : (
            <p role="status" aria-live="polite">
              {siteTranslations.speciesFilter.noForCategory}
            </p>
          )}
        </Options>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: ${em(14)};
  }
`

const Main = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};

  > svg {
    width: ${em(12)};
    height: ${em(12)};
    position: absolute;
    top: 50%;
    right: ${em(10)};
    translate: 0 -50%;

    ${({ $optionsActive }) =>
      $optionsActive &&
      css`
        scale: -1;
      `}
  }
`

const Toggle = styled.button.attrs({ type: `button` })`
  ${({ theme }) => theme.helpers.lineClamp()}
  ${({ theme }) => theme.fonts.set(`primary`, `bold`)};

  width: 100%;
  padding: ${em(14)};
  padding-right: ${em(60)};
  display: block;
  font-size: ${em(15)};
  border: 1px solid transparent;

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-top: ${em(8)};
    padding-bottom: ${em(8)};
  }

  ${({ $optionsActive }) =>
    $optionsActive &&
    css`
      border-color: ${({ theme }) => theme.colors.hallon};
    `}

  &:hover {
    border-color: ${({ theme }) => theme.colors.hallon};
  }
`

const Clear = styled.button.attrs({ type: `button` })`
  width: ${em(22)};
  height: ${em(26)};
  padding: ${em(5)};
  position: absolute;
  top: 50%;
  right: ${em(28)};
  translate: 0 -50%;

  &:hover {
    color: ${({ theme }) => theme.colors.hallon};
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`

const Options = styled.div`
  ${({ theme }) => theme.helpers.colorizeScrollbar()}

  width: 100%;
  margin-top: ${em(5)};
  padding: ${em(10)};
  position: absolute;
  z-index: ${({ theme }) => theme.zindex.speciesFilter};
  top: 100%;
  left: 0;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 ${em(2)} ${em(6)} rgba(0, 0, 0, 0.2);
  animation: 0.2s ${({ theme }) => theme.easings.default};
  animation-name: ${({ theme }) => theme.animations.slideInY(-10)},
    ${({ theme }) => theme.animations.fadeIn};

  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }

  &:has(> ul) {
    height: clamp(${em(180)}, 30vh, ${em(320)});
  }

  > input {
    width: 100%;
    height: ${em(40)};
    padding: 0 ${em(14)} 0 ${em(36)};
    border: 1px solid ${({ theme }) => theme.colors.mellanbla};
    background-color: ${({ theme }) => theme.colors.ljusbla};
    background-image: ${({ theme }) =>
      theme.helpers.searchUrl(theme.colors.mellanbla)};
    background-position: ${em(10)} center;
    background-size: ${em(18)} ${em(18)};
    background-repeat: no-repeat;
    display: block;

    &::placeholder {
      color: ${({ theme }) => theme.colors.mellanbla};
    }

    &:focus {
      outline: 0;
      border-color: ${({ theme }) => theme.colors.himmelsbla};
    }
  }

  > ul {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)};

    margin-top: ${em(10)};
    font-size: ${em(15)};

    li + li {
      margin-top: ${em(2)};
    }

    button {
      width: 100%;
      display: flex;
      align-items: center;
      gap: ${em(10)};

      &:hover {
        color: ${({ theme }) => theme.colors.hallon};
      }
    }

    svg:first-child,
    .gatsby-image-wrapper {
      width: ${em(40)};
      flex-shrink: 0;
    }

    svg:first-child {
      padding: ${em(2)};
      opacity: 0.4;
      color: ${({ theme }) => theme.colors.foreground};
    }

    svg:last-child {
      width: ${em(14)};
      height: ${em(14)};
      margin-left: auto;
    }
  }

  > p {
    padding: ${em(20)};
    font-size: ${em(15)};
    font-style: italic;
    text-align: center;
  }
`
