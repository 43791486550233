import React from "react"
import styled, { css } from "styled-components"
import { em, hideVisually } from "polished"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import ImagePlaceholder from "../image-placeholder"
import { Heading5 } from "../styled/heading"

export default function MunicipalityCard({
  municipality: { name, path, image, excerpt },
  headingNum,
  hideOutline = false,
}) {
  return (
    <Container $hideOutline={hideOutline}>
      <Link to={path} aria-label={name}>
        <Media>
          {image?.mediaType == `image` && (
            <GatsbyImage image={image} alt={name} />
          )}

          {image?.mediaType == `video` && (
            <video src={image.url} autoPlay playsInline loop muted />
          )}

          {!image && <ImagePlaceholder alt={name} />}
        </Media>

        <Text>
          {name && (
            <Heading5
              as={`h${headingNum}`}
              dangerouslySetInnerHTML={{ __html: name }}
            />
          )}

          {excerpt && <Description>{excerpt}</Description>}
        </Text>
      </Link>
    </Container>
  )
}

const Media = styled.figure`
  width: 100%;
  max-height: ${em(280)};
  aspect-ratio: 3 / 2;
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.imageBackground};

  @media ${({ theme }) => theme.mq.mediumDown} {
    max-height: ${em(180)};
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    display: block;
  }

  video {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    background-color: ${({ theme }) => theme.colors.black};
  }

  .gatsby-image-wrapper,
  video {
    a:hover & {
      opacity: 0.9;
    }
  }
`

// const Description = styled(ParagraphSmall)`
//   margin-top: ${em(6)};
// `

const Text = styled.div`
  padding: ${em(16)} ${em(20)} ${em(20)};
  flex-grow: 1;
  background-color: ${({ theme }) => theme.colors.white};

  a:hover & {
    background-color: ${({ theme }) => theme.colors.ljusbla};
  }
`

const Description = styled.p`
  ${hideVisually()}
`

const Container = styled.article`
  width: 100%;
  display: flex;
  box-shadow: ${({ theme }) => theme.bs.card};

  a {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.white};

    ${({ $hideOutline }) =>
      $hideOutline &&
      css`
        outline: none;
      `}
  }
`
