import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import { em } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import Button from "../button"
import ImagePlaceholder from "../image-placeholder"
import { Heading2, Heading3 } from "../styled/heading"
import { ReactComponent as SvgArrowRight } from "../../assets/images/icons/arrow-right.svg"

export default function PostCard({
  post: { __typename, title, path, image, date, categories },
  headingNum,
  major = false,
  showCta = false,
  hideOutline = false,
}) {
  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          readMore
        }
      }
    }
  `)

  const Heading = major ? Heading2 : Heading3

  return (
    <Container $hideOutline={hideOutline}>
      <Link to={path} aria-label={title}>
        <Media $placeholderShown={!image}>
          {image?.mediaType == `image` && (
            <GatsbyImage image={image} alt={title} />
          )}

          {image?.mediaType == `video` && (
            <video src={image.url} autoPlay playsInline loop muted />
          )}

          {!image && <ImagePlaceholder alt={title} />}
        </Media>

        <Info $major={major}>
          {__typename == `ArticlesJson` && !!categories.length && (
            <Category>{categories[0].name}</Category>
          )}

          {date && (
            <Date dateTime={date} dangerouslySetInnerHTML={{ __html: date }} />
          )}

          {title && (
            <Title as={`h${Math.min(headingNum, 6)}`}>
              <Heading as="span" dangerouslySetInnerHTML={{ __html: title }} />
            </Title>
          )}

          {showCta && (
            <More aria-hidden="true">
              <Button
                as="p"
                display="anchor"
                size="small"
                iconpos="right"
                icon={<SvgArrowRight />}
              >
                {translations.global.readMore}
              </Button>
            </More>
          )}
        </Info>
      </Link>
    </Container>
  )
}

const Media = styled.figure`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.hjortron};

  ${({ $placeholderShown }) =>
    $placeholderShown &&
    css`
      height: calc(100% - ${em(100)});
    `}

  .gatsby-image-wrapper,
  video {
    width: 100%;
    height: 100%;
    display: block;

    a:hover & {
      opacity: 0.8;
    }
  }

  video {
    object-fit: cover;
  }
`

const Info = styled.div`
  width: 100%;
  padding: ${em(16)} ${em(20)} ${em(22)};
  position: relative;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ $major }) =>
    $major &&
    css`
      padding: ${em(28)} ${em(40)} ${em(34)};
    `}

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding: ${em(10)} ${em(20)} ${em(16)};
  }

  a:hover & {
    background-color: ${({ theme }) => theme.colors.ljusbla};
  }
`

const Category = styled.div`
  ${({ theme }) => theme.fonts.set(`secondary`, `semibold`)}

  padding: ${em(6)} ${em(12)};
  position: absolute;
  z-index: 1;
  left: ${em(20)};
  bottom: 100%;
  font-size: ${em(13)};
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.hjortron};
`

const Date = styled.time`
  margin-bottom: ${em(4)};
  display: block;
  font-size: ${em(14)};
`

const Title = styled.h2``

const More = styled.div`
  margin-top: ${em(4)};
`

const Container = styled.article`
  width: 100%;
  display: flex;
  box-shadow: ${({ theme }) => theme.bs.card};

  a {
    width: 100%;
    min-height: 100%;
    padding-top: 50%;
    position: relative;
    display: flex;
    align-items: flex-end;
    outline-offset: ${em(6)};

    ${({ $hideOutline }) =>
      $hideOutline &&
      css`
        outline: none;
      `}
  }
`
